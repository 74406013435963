
import axios from "axios";
import { createBrowserRouter, useNavigate } from "react-router-dom";


//import {createBrowserHistory} from history 

//const history = createBrowserHistory()

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`, // our API base URL
  });
  
  // Request interceptor for adding the bearer token
  axiosInstance.interceptors.request.use(
    (config) => {
      console.log('interceptor')
      const token = localStorage.getItem('userToken'); // Assuming you store the token in localStorage
      const customToken = JSON.parse(token)

      if (customToken) {
        config.headers.jwt = `${customToken}`;
      }else{
        window.localStorage.clear()
        window.location.href = "/login"
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );



  axiosInstance.interceptors.response.use(
    (response) =>{
      localStorage.setItem('userToken','')
      localStorage.setItem('userToken', JSON.stringify(response.headers.jwt))
      
      return response
    },
    (error) => {
        console.log(error)
        //alert(error.message)
        if (error.response.status === 401 || error.response.status === 403) {
          window.localStorage.clear()
          window.location.href = "/login"
          //window.location.reload()
          //history.push('/login')
          return false
        }

  
      return Promise.reject(error); 
    },
  )
  
  export default axiosInstance