import { useEffect, useState } from 'react';
import { Lock, Mail } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
//import AuthsServices from '../services/AuthsServices';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../services/AuthInterceptor'
import Otp from './Otp';
//import { useAuth } from '../context/AuthContext';


const Login = () =>{
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [checkOtp, setcheckOtp] =useState(false)
  const [customError, setcustomError] = useState(' ')
  const [getMfa, setMfa] = useState(false)
  const [otp, setOtp] = useState('')
  const navigate = useNavigate();

  const[checkotpexp, setcheckotpexp] = useState(false)

  //const {setisAuthenticated} = useAuth()

  const [getOptemail, setOptemail] = useState('')
  const [loginToken, setLoginToken] = useState('')



  //timmer

  const [timeleft, settimeleft]  =useState(0)
  const [isRunning, setisRunning] = useState(false)


  
  const onSubmit = async (data) => {
      await axios.post(`${process.env.REACT_APP_PRODUCTION_API_AUTH_URL}login`, data)
      .then(function (response) {
        if(response && response.status === 200){
          console.log(response)
          if(response?.data.mfa === true && response?.data.otp_sent === true){
            setMfa(true)
            setOptemail(data.username)
            toast.success(response?.statusText)

         
            // localStorage.setItem('userdata', JSON.stringify(response?.data))
            // localStorage.setItem('userToken', JSON.stringify(response?.data.jwt))
            // localStorage.setItem('userRole', 'admin')
            setLoginToken(response?.data.jwt)
            settimeleft(120)
            setisRunning(true)
            //localStorage.setItem('userRole', JSON.stringify(response.data.user_type))
            reset()
            setTimeout(()=>{
              setcheckotpexp(true)
            }, 20000)

        



          }
          if(response?.data.mfa === true && response?.data.otp_sent === false){
            setMfa(true)
            setOptemail(data.username)
            toast.success(response?.statusText)
            // localStorage.setItem('userdata', JSON.stringify(response?.data))
            // localStorage.setItem('userToken', JSON.stringify(response?.data.jwt))
            // localStorage.setItem('userRole', 'admin')
            setLoginToken(response?.data.jwt)
            settimeleft(120)
            setisRunning(true)
            //localStorage.setItem('userRole', JSON.stringify(response.data.user_type))
            reset()
            setTimeout(()=>{
              setcheckotpexp(true)
            }, 20000)
          }
       
          //navigate('/')
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log('error message')
        //  console.log(error.response.data)
         //setcustomError(error.response.data)
         toast.error(error.response.data)
         reset()
      });
   
  };

const handelOtpChange =  (event) =>{
  setOtp(event.target.value)
}

const changeHandlercheck = () =>{

}



  const handleOtpSubmit = async (e) => {
    e.preventDefault()
// console.log(otp)
// const tokenNew = localStorage.getItem('userToken'); // Assuming you store the token in localStorage
// const customTokenNew = JSON.parse(tokenNew)
// console.log(customTokenNew)

      await axios.post(`${process.env.REACT_APP_OTP_API_AUTH_URL}verify_otp`, {"api_data":{
        "otp": JSON.parse(otp)}}, 
        {
          //headers: {'jwt': customTokenNew}
          headers: {'jwt': loginToken}
        }
)
      .then(function (response) {
        console.log(response)
        if(response && response.status === 200){
          if(response.data.otp_verified.message === 'success'){
            localStorage.setItem('userToken', JSON.stringify(loginToken))
            localStorage.setItem('userRole', JSON.stringify(response.data.user_type))
           // setisAuthenticated(true)
            navigate('/')
           // setLoginToken(' ')
            reset()
          }
          else{
            toast.error(response?.data.otp_verified.message)
            reset()
            window.localStorage.clear()
            setMfa(true)
          
          }
         
         
        }else{
          console.log(response)
          toast.error(response.data.otp_verified.message)
        }
      })
      .catch(function (error) {
        console.log('error message')
        //  console.log(error.response.data)
         setcustomError(error.response.data)
         toast.error(error.response.data)
         reset()
      });
   
  };





  const refreshOtp = async (e) => {
    e.preventDefault()

      await axios.post(`${process.env.REACT_APP_OTP_API_AUTH_URL}get_new_otp`, {"username":getOptemail}, 
        {
          //headers: {'jwt': customTokenNew}
          headers: {'jwt': loginToken}
        }
)
      .then(function (response) {
        console.log(response)
        if(response && response.status === 200){
          if(response.data.otp_sent === true){
            localStorage.setItem('userToken', JSON.stringify(loginToken))
            localStorage.setItem('userRole', JSON.stringify(response.data.user_type))
            setcheckotpexp(false)
            reset()
            settimeleft(120)
            setisRunning(true)
          }
          else if(response.message == '"internal error in JWT"'){
            navigate('/login')
            setMfa(false)
            setcheckotpexp(true)
          }
          else{
            toast.error(response?.data.otp_verified.message)
            setcheckotpexp(true)
            window.localStorage.clear()
            navigate('/login')
            setMfa(false)
            reset()
          }
         
         
        }else{
          console.log(response)
          toast.error(response.data.otp_verified.message)
        }
      })
      .catch(function (error) {
        console.log('error message')
        //  console.log(error.response.data)
         setcustomError(error.response.data)
         toast.error(error.response.data)
         reset()
      });
   
  };



  useEffect(()=>{

    window.history.pushState(null, null, window.location.href)
    localStorage.clear()
    if(localStorage.getItem('userToken')){
      navigate('/')
    }else{
      setLoginToken('')
      window.localStorage.clear()
      window.localStorage.removeItem('userToken')
      navigate('/login')
    }
    const handlePopState = () =>{
      window.history.pushState(null, null, window.location.href)
      localStorage.clear()
    }
    window.addEventListener('popstate', handlePopState)

    return(()=>{
      window.removeEventListener('popstate', handlePopState)

    })

  },[])
  


  useEffect(()=>{
    let timer;
    if(isRunning && timeleft > 0){
        timer = setTimeout(()=>{
            settimeleft(timeleft - 1)
        }, 1000)
    }else if(timeleft === 0 && isRunning){
        setisRunning(false)
    }


    return ()=> clearTimeout(timer) 


},[timeleft, isRunning])


    return(
        <>
  <ToastContainer
    	position="top-right"
    	/>




{getMfa && getMfa ? 
           <section className="my__logindesign">
           <div className="auth-main">
     <div className="auth-wrapper v1">
         <div className="auth-form">
             <div className="position-relative my-5">
                 <div className="auth-bg">
                     <span className="r"></span>
                     <span className="r s"></span>
                     <span className="r s"></span>
                     <span className="r"></span>
                 </div>
             </div>
         </div>
     </div>
 </div>

 <div className="card mb-0 my__card otpcard__new"><div className="card-body">
 <div className="formContainer">
     <div className="logoContainer"><img src={process.env.PUBLIC_URL + '/pulse_logo.png'} width="90px" alt="logo" title="logo"/>  </div>
  
     <h4 className="text-center f-w-500 mt-4 mb-0 login_classms">E-mail authentication</h4>
     <p className="otpnote__new">Please enter the verification code sent through email.</p>
     <form onSubmit={handleOtpSubmit} autoComplete="off">
         <div className="input__field__outer">
           <div className="input__field">
          
             <div className="in__field d-flex"> 
                <input type="text" autoComplete="off" className="form-control__custom"  name='requestedOtp' onChange={handelOtpChange} defaultValue="" placeholder='Enter OTP' required  pattern="[0-9]+"/>
                {isRunning == false ? <div className="refreshotp btn btn-primary btn-block loginbutton" onClick={refreshOtp}>Regenerate OTP</div> : null}
                </div>
           </div>
           <small className='text-success' style={{fontSize:'10px', fontWeight:'bold'}}>Please check your Email. OTP Valid only for 2 minutes.</small>
        

 
           </div>
 
           {/* {isRunning ? `Resend otp in ${timeleft} Second` : 'send opt'} */}
  
           <div className="input__field__outer text-center">


           {/* {checkotpexp ? <div className="refreshotp btn btn-primary btn-block loginbutton" onClick={refreshOtp}>Regenerate OTP</div> : ' '}
 */}

{isRunning ? <div className="normal-ttext">you have {timeleft} Second left</div>  : null}

           <button type="submit" className="btn btn-primary btn-block loginbutton cs__loginbtn">Submit</button>
           </div>
         </form>
 </div></div></div>
         </section>

:  
        <section className="my__logindesign">
          <div className="auth-main">
    <div className="auth-wrapper v1">
        <div className="auth-form">
            <div className="position-relative my-5">
                <div className="auth-bg">
                    <span className="r"></span>
                    <span className="r s"></span>
                    <span className="r s"></span>
                    <span className="r"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="card mb-0 my__card"><div className="card-body">
<div className="formContainer">
    <div className="logoContainer"><img src={process.env.PUBLIC_URL + '/pulse_logo.png'} width="90px" alt="logo" title="logo"/> </div>
    <h4 className="text-center f-w-500 mt-4 mb-0 login_classms">Login</h4>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="input__field__outer">
            <div className="input__field">
                <div className="in__field">
                    <input type="email" autoComplete="off" className="form-control__custom" placeholder="Email address" name='username' {...register("username", {required: true, pattern: /^\S+@\S+$/i})}/>
                </div>
                {/* <div class="input__icon"><Mail color="black"/></div> */}
            </div>
            <p className="error__field">
                {errors.username && errors.username.type === "required" && <span>This is required</span>} {errors.username && errors.username.type === "pattern" && <span>Please enter correct email</span>}
            </p>
        </div>
        <div className="input__field__outer">
            <div className="input__field">
                <div className="in__field">
                    <input type="password"  autoComplete="off" className="form-control__custom" placeholder="Password" name='password'  {...register("password", { required: true})}/>
                </div>
                {/* <div class="input__icon"><Lock color="black"/></div> */}
            </div>
            <p className="error__field"> {errors.password && errors.password.type === "required" && <span>This is required</span>}</p>
        </div>
        {/* <div className="d-flex mt-1 justify-content-between align-items-center login__checkbox"><div className="form-check"><input className="form-check-input input-primary" type="checkbox" id="customCheckc1" onChange={changeHandlercheck} checked="checked"/> <label className="form-check-label text-muted" htmlFor="customCheckc1">Remember me?</label></div><h6 className="text-secondary f-w-400 mb-0">Forgot Password?</h6></div> */}
        <div className="input__field__outer btnContainer">
            <button type="submit" className="btn btn-primary btn-block loginbutton cs__loginbtn" disabled={isRunning}>Log In</button>
        </div>
    </form>
</div></div></div>
        </section>}
       

            {/* <section className="loginouter">
                <div className="logininner">
                    <div className="loginlogo">
                         <h1>PULSE</h1>
                    </div>
                    <div className="loginform">
                        <div className="login__subheading">Sign in to start your session</div>

                  
                            {customError && <p className="error__field">{customError}</p>}
                  

                        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group mt-3">
          <input type="email" className="form-control" placeholder="Email" name='username' {...register("username", {required: true, pattern: /^\S+@\S+$/i})}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <Mail color="black"/>
            </div>
          </div>
        </div>
        <p className="error__field"> {errors.username && errors.username.type === "required" && <span>This is required</span>} {errors.username && errors.username.type === "pattern" && <span>Please enter correct email</span>}</p>
        <div className="input-group mt-3">
          <input type="password" className="form-control" placeholder="Password" name="password"  {...register("password", { required: true, minLength: 6})} />
          <div className="input-group-append">
            <div className="input-group-text">
              <Lock color="black"/>
            </div>
          </div>
        </div>
        <p className="error__field"> {errors.password && errors.password.type === "required" && <span>This is required</span>} {errors.password && errors.password.type === "minLength" && <span>Please enter more the 6 character</span>}</p>
      
        <div className="row">
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-primary btn-block loginbutton">Log In</button>
          </div>
        </div>
      </form>

   

                    </div>
                </div>
            </section> */}
        </>
    )
}

export default Login