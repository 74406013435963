import React from "react"

import { Edit, Trash2 } from "react-feather"
import { Link } from "react-router-dom"


const Userlisting = () =>{

    const UserData = [
        {
            "id":"1",
            "name":'samreh kumar',
            "email":'samresh.k@cheil.com',
            "UserType":"Admin"
        },
        {
            "id":"2",
            "name":'samreh kumar',
            "email":'samresh.k@cheil.com',
            "UserType":"Admin"
        },
        {
            "id":"3",
            "name":'samreh kumar',
            "email":'samresh.k@cheil.com',
            "UserType":"Admin"
        },
        {
            "id":"4",
            "name":'samreh kumar',
            "email":'samresh.k@cheil.com',
            "UserType":"Admin"
        }
        
    ]



    return(
        <>
        <div className="home__page">
           
      
        <div className="add_user mb-3"><Link to="/admin/adduser/" className="btn btn-primary">Add User</Link></div>
        <div className="userlisting__table">
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>User Type</th>
                    <th>Action</th>
                </tr>
            </thead>
        <tbody>
      
            {UserData.map((item, index)=>(
                <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.UserType}</td>
                    <td>
                    <Link><Edit/></Link>
                    <Link><Trash2/></Link>
                </td>
                </tr>
            ))}
               
        
        </tbody>
        </table>
        </div>
          </div>
        </>
    )
}
export default Userlisting