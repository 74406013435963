import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const RouterProtection = (props) =>{
    const {Component} = props
    
    
    const navigate =useNavigate()

    useEffect(()=>{
        const loginToken = localStorage.getItem('userToken')
       
        if(!loginToken){
            window.localStorage.clear()
            navigate('/login')
            //navigate('/#/login')
        }else{

        }

    },[])

    return(
        <>
        <Component/>
        </>
    )
}

export default RouterProtection