import { createContext, useContext, useState } from "react";



 const AuthContext = createContext()

export const AuthProvider = ({children})=>{
   // const [isAuthenticated, setisAuthenticated] = useState(false)
    const logout = () =>{
       // setisAuthenticated(false)
       window.localStorage.clear();
    }

return(
    <AuthContext.Provider value={{logout}}>
    {children}
    </AuthContext.Provider>
)


}

export const useAuth = () => useContext(AuthContext)
