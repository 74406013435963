const Datanotfound = () =>{
    return(
        <>
       <div className="nodatafound">
      <p> Data not found.</p>
       </div>
        </>
    )
}

export default Datanotfound