import { useForm } from 'react-hook-form';
import axiosInstance from '../services/AuthInterceptor'
import {ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddUser = () =>{
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const navigate = useNavigate()

    const onaddUserSubmit = async (data) => {
            console.log(data)
        await axiosInstance.post(`${process.env.REACT_APP_OTP_API_AUTH_URL}create_user`, data)
        .then(function (response) {
          if(response && response.status === 200){
            console.log(response)
            toast.info(response.data.message)
            reset()
                
          }else{
            console.log(response)
          }
        })
        .catch(function (error) {
          console.log('error message')
           toast.error(error.response.data)
           reset()
        });
     
    };



    return(
        <>

<ToastContainer
    	position="top-right"
    	/>





       <div className="home__page">
        <div className="adduserform__outer">
       <div className="row">
              
              <div className="col-xl-12 col-md-12">
               <div className="card">
                   <div className="card-body ">
        <div className="user__regstrationform">
            <form onSubmit={handleSubmit(onaddUserSubmit)} autoComplete="off">
                <div className="row">
                    <div className="col-md-4">
                    <div className="form-group">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input type="email" autoComplete="off" className="form-control" placeholder="Email address" name='username' {...register("username", {required: true, pattern: /^\S+@\S+$/i})}/>
                    </div>
                    <p className="error__field">
                {errors.username && errors.username.type === "required" && <span>This is required</span>} {errors.username && errors.username.type === "pattern" && <span>Please enter correct email</span>}
            </p>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                    <label htmlFor="product_access" className="form-label">Product access</label>
                    <select className="form-select" id="product_access" name="product_access" {...register("product_access", {required: true})}>
                        <option>Select Product</option>
                        <option value="pulse">Pulse</option>
                    </select>
                    </div>

                    <p className="error__field">
                {errors.product_access && errors.product_access.type === "required" && <span>This is required</span>} 
            </p>


                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                    <label htmlFor="user_type" className="form-label">Select User Type</label>
                    <select className="form-select" id="user_type" name="user_type" {...register("user_type", {required: true})}>
                        <option>Select User Type</option>
                        <option value="admin">Admin</option>
                        <option value="normal">User</option>
                    </select>
                    </div>
                    <p className="error__field">
                    {errors.user_type && errors.user_type.type === "required" && <span>This is required</span>} 
                    </p>
                    </div>
                    <div className="form-group col-md-12 text-center" style={{paddingTop:'30px'}}><button type="submit" className="btn btn-dark text-center">Submit</button></div>
                </div>
            </form>
        </div>
       </div>
       </div>
       </div>
       </div>
       </div>
       </div>
        </>
    )
}

export default AddUser