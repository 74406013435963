import { Lock, Mail, User } from 'react-feather';
import { useForm } from 'react-hook-form';

const Signup = () =>{
  const { register, handleSubmit, formState: { errors } } = useForm();
  // const onSubmit = data => console.log(data);
  // console.log(errors);

  const onSubmit = async (data) => {
    // async request which may result error
    try {
      // await fetch()
      console.log(data)
    } catch (e) {
      // handle your error
    }
  };

    return(
        <>
            <section className="loginouter">
                <div className="logininner">
                    <div className="loginlogo">
                         <h1>PULSE</h1>
                    </div>
                    <div className="loginform">
                        <div className="login__subheading">Sign in to start your session</div>


                        <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-group mt-3">
          <input type="text" className="form-control" placeholder="Name" name="fullname" {...register("fullname", {required: true})}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <User color="black"/>
            </div>
          </div>
        </div>
       <p className="error__field"> {errors.fullname && errors.fullname.type === "required" && <span>This is required</span>}</p>
        <div className="input-group mt-3">
          <input type="email" className="form-control" placeholder="Email" name="email" {...register("email", {required: true, pattern: /^\S+@\S+$/i})}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <Mail color="black"/>
            </div>
          </div>
        </div>
        <p className="error__field"> {errors.email && errors.email.type === "required" && <span>This is required</span>} {errors.email && errors.email.type === "pattern" && <span>Please enter correct email</span>}</p>
        <div className="input-group mt-3">
          <input type="password" className="form-control" placeholder="Password" name="password"  {...register("password", { required: true, minLength: 6})}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <Lock color="black"/>
            </div>
          </div>
        </div>
        <p className="error__field"> {errors.password && errors.password.type === "required" && <span>This is required</span>} {errors.password && errors.password.type === "minLength" && <span>Please enter more the 6 character</span>}</p>
        <div className="row">
         
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-primary btn-block loginbutton">Sign Up</button>
          </div>
        </div>
      </form>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Signup