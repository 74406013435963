import React, { useState, useEffect } from 'react';

function Profile() {

  const [seconds, setSeconds] = useState(0)
  const [minutes, setMinutes] = useState(2)



  function updateTime() {
    if (minutes == 0 && seconds == 0) {
      //reset
      setSeconds(0);
      setMinutes(0);
      alert();
    }
    else {
      if (seconds == 0) {
        setMinutes(minutes => minutes - 1);
        setSeconds(59);
      } else {
        setSeconds(seconds => seconds - 1);
      }
    }
  }



  useEffect(() => {
    // use set timeout and be confident because updateTime will cause rerender
    // rerender mean re call this effect => then it will be similar to how setinterval works
    // but with easy to understand logic
    const timeOutnew = setTimeout(updateTime, 1000)

 

    return function cleanUp() {
      clearTimeout(timeOutnew);
    }
  })




  return (<p>
    time: 0{minutes}:{seconds}
  </p>);
}

export default Profile