import Header from "../components/Header";
import { Outlet } from 'react-router-dom';
import SideBar from "../components/SideBar";
import { useState } from "react";
import { AuthProvider } from "../context/AuthContext";

const HomeLayout = () =>{
    const [isDivVisible, setisDivVisible] = useState(true)
    
    const toggleVisibility = () =>{
        setisDivVisible((prev)=> !prev)
    }

    return(
        <>
        <AuthProvider>
        <Header visibility={toggleVisibility} isVisible ={isDivVisible} />
        {/* <SideBar isVisible ={isDivVisible} /> */}
        <section className={isDivVisible ? "pc__container" : "pc__container active" }>
        <Outlet />
        </section>
        </AuthProvider>
        </>
    )
}

export default HomeLayout;