import { ArrowUp, Calendar } from 'react-feather';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState, useMemo, Suspense} from 'react';
import {Chart as ChartJs, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend} from 'chart.js/auto';
import { Line, Bar } from 'react-chartjs-2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../services/AuthInterceptor'
import { useForm } from 'react-hook-form';
import WordCloud from 'react-d3-cloud';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import DownloadData from './DownloadData';
import * as XLSX from "xlsx";
import { useNavigate } from 'react-router-dom';
import Datanotfound from '../components/Datanotfound';
import Loadding from '../components/Loadding';

//ChartJs.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend)
ChartJs.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ChartDataLabels)
const Engagement = () =>{
 
  const convCheckboxRfe = useRef(null)
  const dayCheckboxRfe = useRef(null)

  const navigate = useNavigate()


    const currentData = new Date().toJSON().slice(0,10)

    const defaultDeviceValue = {
      "device": "Fold6",
      "id": 14
  };
    const defaultDurationStartValue = {
      "date_start": "L-30",
      "id": 16
  };
    const defaultDurationEndValue = {
      "date_end": "L+3",
      "id": 49
  };
    const defaultPlatformValue = {
      "id": 6,
      "platform": "INSTAGRAM"
  };
  var resultasaSas;


    const [getOnLoadDeviceList, SetonLoadDeviceList] = useState([defaultDeviceValue])
    const [getDurationStartList, SetDurationStartList] =   useState([defaultDurationStartValue])
    const [getDurationEndList, SetDurationEndList] =   useState([defaultDurationEndValue])
    const [getPlatformList, SetPlatformList] =   useState([defaultPlatformValue])

  const [deviceValueChange, setdeviceValueChange] = useState(false)
  const [durationStartChange, setDurationStartChange] = useState(false)
  const [durationEndChange, setDurationEndChange] = useState(false)
  const [platformChange, setplatformChange] = useState(false)

  const [selectedDevice, SetselectedDevice]= useState({})
  //const [selectedDevicename, setselectedDevicename] = useState()
  const [selectedStartDate, SetselectedStartDate]= useState({})
  const [selectedEndDate, SetselectedEndDate]= useState({})
  const [selectedplatform, Setselectedplatform]= useState({})

  const [deviceId, setDeviceId] = useState('')



  const [selectedplatformName, SetselectedplatformName]= useState('')

    const [ConversationsTrend, setConversationsTrend] = useState([])
    const [CountConversations, setCountConversations] = useState()

    const [overallCounts, setoverallCounts] = useState({})
  
    const [sentimentDatanegative, setsentimentDatanegative] = useState({
      "share":"",
      "volume":""
    })
    const [sentimentDataneutral, setsentimentDataneutral] = useState({})
    const [sentimentDatapositive, setsentimentDatapositive] = useState({})
    

    const [overallNps, setoverallNps] = useState()
    const [conversationsChart, setConversationsChart] = useState({})


    const [convonloadValue, setConvonloadValue]=useState()
    const [dayonloadValue, setDayonloadValue]=useState()


    const [brandContent, setbrandContent] =useState({})
    const [brandEngagement, setbrandEngagement] =useState({})
    const [engagementPerContent, setengagementPerContent] =useState({})

    const [engagementBreakup, setengagementBreakup] =useState({})

    const [SentimentTrend, setSentimentTrend] = useState([])

    const [DriversOfSentiment, setDriversOfSentiment]= useState([])

    const [chartPhasesDriversPreLaunch, setChartPhasesDriversPreLaunch] = useState({})
    const [chartPhasesDriversLaunch, setChartPhasesDriversLaunch] = useState({})
    const [chartPhasesDriversPostLaunch, setChartPhasesDriversPostLaunch] = useState({})


    
    const [chartPhasesDriversPreLaunchTotal, setChartPhasesDriversPreLaunchTotal] = useState({})
    const [chartPhasesDriversLaunchTotal, setChartPhasesDriversLaunchTotal] = useState({})
    const [chartPhasesDriversPostLaunchTotal, setChartPhasesDriversPostLaunchTotal] = useState({})
    

    const [chartPhasesPlatformNpsPreLaunch, setchartPhasesPlatformNpsPreLaunch] = useState({})
    const [chartPhasesPlatformNpsLaunch, setchartPhasesPlatformNpsLaunch] = useState({})
    const [chartPhasesPlatformNpsPostLaunch, setchartPhasesPlatformNpsPostLaunch] = useState({})

    const [downloadTabledataHeaders, setdownloadTabledataHeaders] = useState([])
const [downloadTabledata, setdownloadTabledata] = useState([])



const[finalFormData, setfinalFormData]= useState({})

const [topicList, setTopicList] =useState([])
const [selectedTopic, setSelectedTopic] = useState('6')
const [getTopicdata, setTopicData] = useState([])
const [getTopicHeader, setTopicHeader] = useState([])
const [loadinghome, setloadinghome] = useState(false)

const [sentimentdata, Setsentimentdata] = useState([])




//tabbing
const [activeTab, setActiveTab] = useState('tab-1');

const handleClickTab = (event) => {
  setActiveTab(event.target.id);
};


const onLoadDeviceList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}device_list`,{}
  )
    .then(function (response) {
      if(response && response.status === 200){
         console.log(response.data.data)
          SetonLoadDeviceList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
      console.log(error.message)
     
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}


const onLoadDurationStartList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}duration_list`,{}
  )
    .then(function (response) {
      if(response && response.status === 200){
          console.log(response.data.data)
          SetDurationStartList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
 
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}

const onLoadDurationEndList = async () =>{
    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}duration_list`,{}
  )
    .then(function (response) {
      if(response && response.status === 200){
         // console.log(response.data.data)
          SetDurationEndList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
 
}

const onLoadPlatformList = async () =>{

    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}platform_list`,{}
  )
    .then(function (response) {
      if(response && response.status === 200){
         //console.log(response.data.data)
          SetPlatformList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });

}





var loadData ={
  "device": defaultDeviceValue.id,
  "date_start": defaultDurationStartValue.id,
  "date_end": defaultDurationEndValue.id,
  "platform": defaultPlatformValue.id
}

const {platform, ...rest } = loadData;


const getoverallCount = async (deviceId) =>{

    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}overall_counts`,{
      "api_data":{...deviceId}
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
         console.log(response.data.data)
         setoverallCounts(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
 
}



const getChartConversations = async (countData, freValue) =>{
  console.log(countData, freValue)
  setloadinghome(true)
    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}content_trends`,{
      "api_data":{...countData,"frequency": freValue} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
       console.log(response.data.data)
        setConversationsChart(response.data.data)
        setloadinghome(false)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
     
    });

}



const getBrandContent = async (countData) =>{
  console.log(countData)
    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}brand_content`,{
      "api_data":{...countData} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response.data.data)
        setbrandContent(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
 
}


const getBrandEngagement = async (countData) =>{
  console.log(countData)
    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}brand_engagement`,{
      "api_data":{...countData} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response.data.data)
        setbrandEngagement(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
 
}



const getEngagemenPerContent = async (countData) =>{
  console.log(countData)
    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}engagement_per_content`,{
      "api_data":{...countData} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response.data.data)
        setengagementPerContent(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
 
}


const getEngagementBreakup = async (countData) =>{
  console.log(countData)
    await axiosInstance.post(`${process.env.REACT_APP_API_CONTENT_URL}engagement_breakup`,{
      "api_data":{...countData} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response.data.data)
        setengagementBreakup(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
 
}

// const getSentimentTrend = async (countData, freValue) =>{
 
//     await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_sentiment_trend`,{
//       "api_data":{...countData,"frequency": freValue} ,      
//     }
//   )
//     .then(function (response) {
//       if(response && response.status === 200){
//         console.log(response.data.data)
//         setSentimentTrend(response.data.data)
//       }else{
//         console.log(response)
//       }
//     })
//     .catch(function (error) {
//      console.log('error message')
//     });
 
// }

// const getDriversOfSentiment = async (countData) =>{
 
//     await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_drivers_of_sentiment`,{
//       "api_data":{...countData} ,      
//     }
//   )
//     .then(function (response) {
//       if(response && response.status === 200){
//         console.log(response.data.data)
//         setDriversOfSentiment(response.data.data)
//       }else{
//         console.log(response)
//       }
//     })
//     .catch(function (error) {
//      console.log('error message')
     
//     });
 
// }

// const getChartPhasesDrivers = async (countData) =>{
 
//     await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_phases_drivers`,{
//       "api_data":{...countData} ,      
//     }
//   )
//     .then(function (response) {
//       if(response && response.status === 200){
//         console.log(response.data.data)
//         console.log(response.data.data.pre_launch)
//         setChartPhasesDriversPreLaunch(response.data.data.pre_launch)
//         setChartPhasesDriversPreLaunchTotal(response.data.data.pre_launch.totals)
//         setChartPhasesDriversLaunch(response.data.data.launch)
//         setChartPhasesDriversLaunchTotal(response.data.data.launch.totals)
//         setChartPhasesDriversPostLaunch(response.data.data.post_launch)
//         setChartPhasesDriversPostLaunchTotal(response.data.data.post_launch.totals)
//       }else{
//         console.log(response)
//       }
//     })
//     .catch(function (error) {
//      console.log('error message')
//     });
 
// }

// const getChartPhasesPlatformNps = async (countData) =>{
 
//     await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_phases_platform_nps`,{
//       "api_data":{...countData} ,      
//     }
//   )
//     .then(function (response) {
//       if(response && response.status === 200){
//        //console.log(response.data.data)
//         setchartPhasesPlatformNpsPreLaunch(response.data.data.pre_launch)
//         setchartPhasesPlatformNpsLaunch(response.data.data.launch)
//         setchartPhasesPlatformNpsPostLaunch(response.data.data.post_launch)
       
//       }else{
//         console.log(response)
//       }
//     })
//     .catch(function (error) {
//      console.log('error message')
     
//     });
 
// }


























 

useEffect(()=>{
 // onloaData()

 
 const finalDeviceValueB = deviceValueChange ? selectedDevice : defaultDeviceValue.id
 const finalStartDateValueB = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
 const finalEndDateValueB = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
 const finalPlateformValueB = platformChange ? selectedplatform : defaultPlatformValue.id

 const formDataB = {
     "device": parseInt(finalDeviceValueB),
     "date_start": parseInt(finalStartDateValueB),
     "date_end": parseInt(finalEndDateValueB),
     "platform": parseInt(finalPlateformValueB)
 }

 const{platform, ...rest} = formDataB

  onLoadDeviceList()
  onLoadDurationStartList()
  onLoadDurationEndList()
  onLoadPlatformList()

  getoverallCount(formDataB)

  getEngagementBreakup(formDataB)

  getChartConversations(formDataB, dayCheckboxRfe.current?.value)
  //getSentimentTrend(formDataB, dayCheckboxRfe.current?.value)

 if(convCheckboxRfe.current){
  setConvonloadValue(convCheckboxRfe.current.value)
 }

 if(dayCheckboxRfe.current){
  setDayonloadValue(dayCheckboxRfe.current.value)
 }

 getBrandContent(rest)
 getBrandEngagement(rest)
 getEngagemenPerContent(rest)

 //getDriversOfSentiment(formDataB)  

 //getChartPhasesDrivers(formDataB)
 //getChartPhasesPlatformNps(rest)

 setfinalFormData(formDataB)



 return(()=>{
  console.log('clearning up')
  
})





}, [])

// defaultDeviceValue.id, defaultDurationEndValue.id, defaultDurationStartValue.id, defaultPlatformValue.id, deviceValueChange, durationEndChange, durationStartChange, platformChange, selectedDevice, selectedEndDate, selectedStartDate, selectedTopic,selectedplatform


// const handledatachange = (event) =>{
//   const { name, value } = event.target;
//   setFromData((prevFormData) => ({ ...prevFormData, [name]: value }));
// }


const handleDeviceChange = (event) =>{
  SetselectedDevice(event.target.value)
  setdeviceValueChange(true)
 
}

const changestartDuration = async (duradionId) =>{
  //alert(duradionId)
      await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list_get_right_list`,{"api_data": {
      "duration_id": JSON.parse(duradionId)
    }
  }
    )
      .then(function (response) {
        if(response && response.status === 200){
            console.log(response.data.data)
            SetDurationEndList(response.data.data)
            console.log(getDurationEndList)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
       console.log('error message')
       toast.error(error)
   
      });
   
  }




const handleDatestart = (event) =>{
  SetselectedStartDate(event.target.value)
  setDurationStartChange(true)
  setDeviceId(event.target.value)
 // alert();

  changestartDuration(event.target.value)
  //alert(event.target.value)
}

const changeEndDuration = async (duradionId) =>{
 // alert(duradionId)
      await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list_get_left_list`,{"api_data": {
      "duration_id": JSON.parse(duradionId)
    }
  }
    )
      .then(function (response) {
        if(response && response.status === 200){
            console.log(response.data.data)
            SetDurationStartList(response.data.data)
            console.log(getDurationEndList)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
       console.log('error message')
       toast.error(error)
   
      });
   
  }



 
const handleDateend = (event) =>{
  SetselectedEndDate(event.target.value)
  setDurationEndChange(true)
  console.log(getDurationEndList)
  changeEndDuration(event.target.value)
}

const handlePlatform =(event) =>{
  Setselectedplatform(event.target.value)
  setplatformChange(true)
  console.log(event)
  const ssp = event.target.options[event.target.selectedIndex]
  console.log(ssp.getAttribute('data-ntework'))
  SetselectedplatformName(ssp.getAttribute('data-ntework'))
}


const handleChacboxChangeconv = (event) =>{
  setConvonloadValue(event.target.value)
  //alert(event.target.value)
  //alert(dayonloadValue)



  const finalDeviceValueACO = deviceValueChange ? selectedDevice : defaultDeviceValue.id
  const finalStartDateValueACO = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
  const finalEndDateValueACO = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
  const finalPlateformValueACO = platformChange ? selectedplatform : defaultPlatformValue.id


  const formDataACO = {
    "device": parseInt(finalDeviceValueACO),
    "date_start": parseInt(finalStartDateValueACO),
    "date_end": parseInt(finalEndDateValueACO),
    "platform": parseInt(finalPlateformValueACO)
}

getChartConversations(formDataACO, dayonloadValue)
//getSentimentTrend(formDataACO, dayonloadValue)
}

const handleChacboxChangeday = (event) =>{
  setDayonloadValue(event.target.value)


//alert(event.target.value)


  const finalDeviceValueA = deviceValueChange ? selectedDevice : defaultDeviceValue.id
    const finalStartDateValueA = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
    const finalEndDateValueA = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
    const finalPlateformValueA = platformChange ? selectedplatform : defaultPlatformValue.id

    const formDataA = {
        "device": parseInt(finalDeviceValueA),
        "date_start": parseInt(finalStartDateValueA),
        "date_end": parseInt(finalEndDateValueA),
        "platform": parseInt(finalPlateformValueA)
    }



  getChartConversations(formDataA, event.target.value)
 // getSentimentTrend(formDataA,  event.target.value)
}


const handleFormData = async (e) => {
  e.preventDefault()
  try {

    const finalDeviceValue = deviceValueChange ? selectedDevice : defaultDeviceValue.id
    const finalStartDateValue = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
    const finalEndDateValue = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
    const finalPlateformValue = platformChange ? selectedplatform : defaultPlatformValue.id

    const formData = {
        "device": parseInt(finalDeviceValue),
        "date_start": parseInt(finalStartDateValue),
        "date_end": parseInt(finalEndDateValue),
        "platform": parseInt(finalPlateformValue)
    }


    const{platform, ...rest} = formData

    getoverallCount(formData)
      //console.log(formData)
      getEngagementBreakup(formData)

      getBrandContent(rest)
      getBrandEngagement(rest)
      getEngagemenPerContent(rest)

      //getDriversOfSentiment(formData)

     // getChartPhasesDrivers(formData)
 //getChartPhasesPlatformNps(rest)


 getChartConversations(formData, dayonloadValue)
 //getSentimentTrend(formData, dayonloadValue)










      //console.log(getOnLoadDeviceList)
  } catch (error) {
    console.log(error)
  }
};









    return(
        <>
        <ToastContainer
    	position="top-right"
    	/>





{/* <WordCloud data={data} /> */}







        <section className="home__page">

    

            <section className="home__section1">
                     {/* <section className="page__hd">Pulse Dashboard</section> */}
            <div className="row">
              
             <div className="col-xl-12 col-md-12">
              <div className="card">
                  <div className="card-body ">
                   <form onSubmit={handleFormData}>
                        <div className="row justify-content-between">
                        <div className="form-group col-md-4">
                                <label className="form-label" htmlFor="campaignname">Select Device</label>
                                  <div className="input-group">
                                  <select className="form-select" id="campaignname" placeholder="Campaign name"  name='device'  onChange={handleDeviceChange} defaultValue={defaultDeviceValue} >
                                         {getOnLoadDeviceList && getOnLoadDeviceList.map((data,index)=>(
                                          <option key={data.id} value={data.id}>{data.device_name}</option>
                                         ))}
                                        </select>
                                    </div>

                            </div>
                          
                            <div className="form-group col-md-4">
                                <label className="form-label" htmlFor="pc-datepicker-10">Date/Time</label>
                                

                          
                                  <div className="input-group date mycustomdate">
                                        {/* <input type="range" className="form-range"  id="start__date" placeholder="Start date"  name='startdate' step="1"  onChange={handledatachange}/> */}
                                        <select className="form-select" id="startdate" name='date_start' onChange={handleDatestart} defaultValue={defaultDurationStartValue}>

                                         {getDurationStartList && getDurationStartList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.duration_name}</option>
                                         ))}


                                        </select>
                                        <span className="input-group-text" max={currentData}>
                                        <Calendar color="#888" size="13"/>
                                        </span> 
                           
                                        <select className="form-select" id="enddate" name='date_end' onChange={handleDateend} defaultValue={defaultDurationEndValue}>
                                        {getDurationEndList && getDurationEndList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.duration_name}</option>
                                         ))}

                                        </select>
                                        {/* <input type="range" className="form-range" id="end__date" placeholder="End date" name='enddate' step="1" onChange={handledatachange} /> */}
                                    </div>

                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="devicename">Select Platform</label>
                               
                                <select className="form-select" id="selectplatform" placeholder="select platform"  name='platform'  onChange={handlePlatform} defaultValue={defaultPlatformValue}>
                                       {getPlatformList && getPlatformList.map((data, index)=>(
                                           <option key={data.id} value={data.id} data-ntework={data.network_name}>{data.network_name}</option>
                                         ))}
                                        </select>
                               
                            </div>


                            <div className='form-group col-md-1 text-right' style={{paddingTop:'30px'}}>
                            {/* <button type="submit" className="btn btn-light">Reset</button> */}
                            <button type="submit" className="btn btn-dark text-right">Search</button>
                            </div>

                            
                        </div>

                      
                      </form>
                  </div>
              </div>
             </div>
      </div>
            </section>






                 
          <section className="home__section21">

<div className="row">
  <div className="col-md-12">
  <div className="row">




<div className="col-md-4 col-xl-4">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-3 newh6heading">Brand Content </h6>
         <div className="row d-flex align-items-center">
             <div className="col-12">
             <Suspense fallback={<Loadding />}>
              {overallCounts.brand_content && overallCounts.brand_content ? <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">{overallCounts.brand_content}</h3> : <Datanotfound/>}
              </Suspense>
                 {/* <h3 className=" m-b-0 boldfont text-center">{overallCounts.brand_content}</h3> */}
             </div>
         </div>
     </div>
 </div>
</div>

<div className="col-md-4 col-xl-4">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-3 newh6heading">Brand Engagement
         </h6>
         <div className="row d-flex align-items-center">
             <div className="col-9">
             <Suspense fallback={<Loadding />}>
              {overallCounts.brand_engagement && overallCounts.brand_engagement ? <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">{overallCounts.brand_engagement}</h3> : <Datanotfound/>}
              </Suspense>
                 
             </div>
         </div>
    
 
     </div>
 </div>
</div>

<div className="col-md-4 col-xl-4">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-3 newh6heading">Engagement per Content
         </h6>
         <div className="row d-flex align-items-center">
             <div className="col-9">
              {overallCounts.engagement_per_content && overallCounts.engagement_per_content ? <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">{overallCounts.engagement_per_content}</h3> : <Datanotfound/>}
                 
             </div>
         </div>
  
      
     </div>
 </div>
</div>

</div>
  </div>
  <div className="col-md-12">
<div className='row'>
<div className="col-xl-12 col-md-12">
<div className="card">
<div className="bd-example">
  <table className="table table-bordered">

  <thead>
    <tr>
      <th scope="col" colSpan="2" style={{textAlign:'left'}}>  Engagement Breakup</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>Likes</th>
      <td>{engagementBreakup && engagementBreakup ? engagementBreakup.likes : <Datanotfound/>}</td>
    </tr>
    <tr>
      <th>Comments</th>
      <td>{engagementBreakup && engagementBreakup ? engagementBreakup.comments : <Datanotfound/>}</td>
    </tr>
    <tr>
      <th>Share</th>
      <td>{engagementBreakup && engagementBreakup ? engagementBreakup.share : <Datanotfound/>}</td>
    </tr> 

    <tr>
      <th>Views</th>
      <td>{engagementBreakup && engagementBreakup ? engagementBreakup.views : <Datanotfound/>}</td>
    </tr>

    <tr>
      <th>Engagement (with Views)</th>
      <td>{engagementBreakup && engagementBreakup ? engagementBreakup.engagement_with_views : <Datanotfound/>}</td>
    </tr>

    <tr>
      <th>Engagement (without Views)</th>
      <td>{engagementBreakup && engagementBreakup ? engagementBreakup.engagement_without_views : <Datanotfound/>}</td>
    </tr>

  </tbody>

  </table>
</div>
  </div>
  </div>
</div>

  </div>
</div>




            </section>

            <div className="home__section21 home__section22">
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Trend line
                         {/* {dayonloadValue} */}
                         </div>
                      <div className="card-body">
                      
<div className="row justify-content-between radiofilter">


{/* <div className="col-md-6 d-flex">
<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="conversations" ref={convCheckboxRfe} onChange={handleChacboxChangeconv} type="radio" value="Conversations" defaultChecked  />
Conversations
</label>
</div>
<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="conversations" onChange={handleChacboxChangeconv} type="radio" value="NPS Daily" />
NPS Daily
</label>
</div>
</div> */}

<div className="col-md-6 d-flex">
<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="content_trends" ref={convCheckboxRfe} onChange={handleChacboxChangeconv} type="radio" value="Brand content" checked={convonloadValue === "Brand content"}  />
Brand content
</label>
</div>



<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="content_trends" onChange={handleChacboxChangeconv} type="radio" value="Brand Engagement" checked={convonloadValue === "Brand Engagement"} />
Brand Engagement
</label>
</div>


</div>






 

  <div className="col-md-6 d-flex justify-content-end">
  <div className="form-check">
 
  <label className="form-check-label" >
  <input className="form-check-input" name="days" ref={dayCheckboxRfe} onChange={handleChacboxChangeday} type="radio" value="daily" checked={dayonloadValue === "daily"}  />
  Daily
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="days" onChange={handleChacboxChangeday}  type="radio" value="weekly" checked={dayonloadValue === "weekly"} />
  Weekly
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="days" onChange={handleChacboxChangeday}  type="radio" value="monthly"  checked={dayonloadValue === "monthly"}  />
  Monthly
  </label>
</div>
  </div>


</div>
<div>



{loadinghome ? <Loadding/> : 
    <Line height="60vw" data={{
        responsive: true,
          labels: conversationsChart?.label,
          datasets: [{
            label: convonloadValue,
            data:convonloadValue === 'Brand content' ? conversationsChart?.brand_content : conversationsChart?.brand_engagement,
            // data:convonloadValue === 'NPS Daily' ? conversationsChart?.data_nps : convonloadValue === 'Conversations' ? conversationsChart?.data_convo : conversationsChart?.cumu_nps,
            borderWidth: 2,
            borderColor:convonloadValue === 'Brand content' ? '#7086a7' : '#ffb1c1' ,
            backgroundColor:convonloadValue === 'Brand content' ? '#7086a7' : '#ffb1c1' ,
            datalabels: {
              display:false,
            }
          }],
        
        options: {
          maintainAspectRatio: true,
          legend: {
            display: false
        },
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            datalabels: {
                display: false,
            },
            font: {
              family:'SamsungOne'
            },
        }
        }
    }}/>
  }
</div>
</div>
</div>
</div>
</div>
            </div>

         


            <section className='home__section3 home__section32'>
<div className="row">
                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Brand Content
                  </div></div>
                      <div className="card-body">
                      <Bar  height="250vh" data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:brandContent?.label,
                                    datasets: [
                                      {
                                        label: 'Brand Content',
                                        data:brandContent?.brand_content,
                                        borderColor:"#a4dcff",
                                        backgroundColor: "#a4dcff",
                                        datalabels: {
                                          color: '#000',
                                          align: 'end',
                                          anchor: 'end',
                                          //rotation: -90,
                                          formatter: function(value,index) {
                                            const csIndex = index.dataIndex
                                            return `${brandContent.distribution[csIndex] }%`
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                       
                                      }
                                    ],
                                    
                                  }} 
                                  
                                  options={{responsive: true,
                                    scales:{
                                      y:{
                                        display:false,
                                        // to remove the x-axis grid
                                        grid: {
                                          drawBorder: false,
                                          display: false,
                                        },
                                      },
                                      x:{
              
                                        grid: {
                                          drawBorder: false,
                                          display: false,
                                        },
                                      }
                                    },
                                    plugins: {
                                      legend: {
                                        position: 'top',
                                      },
                                      title: {
                                        display: true,
                                     
                                      },
                                      font: {
                                        family:'SamsungOne'
                                  }
                                    }
                                  }}/>

                      </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Brand Engagement
                  </div> </div>
                      <div className="card-body">
                    
                      <Bar height="250vh" data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:brandEngagement?.label,
                                    datasets: [
                                      {
                                        label: 'Brand Engagement',
                                        data:brandEngagement?.brand_engagement,
                                        borderColor:"#7086a7",
                                        backgroundColor: "#7086a7",
                                        datalabels: {
                                          color: '#000',
                                          align: 'end',
                                          anchor: 'end',
                                          //rotation: -90,
                                          formatter: function(value,index) {
                                            const csIndex = index.dataIndex
                                            return `${brandEngagement.distribution[csIndex] }%`
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                        
                                      }
                                    ],
                                   
                                  }} options={{responsive: true,
                                    scales:{
                                      y:{
                                        display:false,
                                        ticks: {
                                          display: false,
                                        },
                                  
                                        // to remove the x-axis grid
                                        grid: {
                                          drawBorder: false,
                                          display: false,
                                        },
                                      },
                                      x:{
                              
                                        grid: {
                                          drawBorder: false,
                                          display: false,
                                        },
                                      }
                                    },
                                    plugins: {
                                      legend: {
                                        position: 'top',
                                      },
                                      title: {
                                        display: true,
                                     
                                      },
                                      font: {
                                        family:'SamsungOne'
                                  }
                                    }
                                  }}/>

                      </div>
                  </div>
                </div>

                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Engagement per Content
                  </div></div>
                      <div className="card-body">
                    
                      <Bar height="250vh" data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:engagementPerContent?.label,
                                    datasets: [
                                      {
                                        label: 'Engagement per Content',
                                        data:engagementPerContent?.engagement_per_content,
                                        borderColor:"#ffc6a3",
                                        backgroundColor: "#ffc6a3",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          // formatter: function(value,index) {
                                          //   const csIndex = index.dataIndex
                                          //   return `${engagementPerContent.distribution[csIndex] }%`
                                          // },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                      }
                                    ],
                                 
                                  }} options={{responsive: true,
                                    scales:{
                                      y:{
                                        display:false,
                                        font: {
                                          family:'SamsungOne'
                                        },
                                        ticks: {
                                          display: false,
                                        },
                                  
                                        // to remove the x-axis grid
                                        grid: {
                                          drawBorder: false,
                                          display: false,
                                        },
                                      },
                                      x:{
                                        font: {
                                          family:'SamsungOne'
                                        },
                    
                                        grid: {
                                          drawBorder: false,
                                          display: false,
                                        },
                                      }
                                    },
                                    plugins: {
                                      legend: {
                                        position: 'top',
                                      },
                                      title: {
                                        display: true,
                                     
                                      },
                                      font: {
                                        family:'SamsungOne'
                                      }
                                    }
                                  }}/>

                      </div>
                  </div>
                </div>


          </div>
</section>





        
























{/* for client request */}

{/* 
<section className='home__section4'>
<div className="row">
  <div className='col-md-12'>
<div className="card">
<div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Pre Launch </div> <div className="total__conv__count">Total conversation vol:  {chartPhasesDriversPreLaunchTotal["total conversations"]}
 </div> <div className="total__conv__count">  NPS:   {chartPhasesDriversPreLaunchTotal.NPS}%</div></div>





  <div className='row'>
                <div className="col-xl-6 col-md-6">
                
                   
                      <div className="card-body">
                    
                  <Bar data={{
                      responsive: true,
                      labels:chartPhasesDriversPreLaunch?.labels,
                        datasets: [{
                            label : 'Positive',
                            data: chartPhasesDriversPreLaunch?.positive_percentage,
                            borderWidth: 1,
                            borderColor:"#bcd8a8",
                            backgroundColor: "#bcd8a8",
                            indexAxis: 'y'
                          },
                          {
                            label : 'Negative',
                            data: chartPhasesDriversPreLaunch?.negative_percentage,
                            borderWidth: 1,
                            borderColor:"#ff8585",
                            backgroundColor:"#ff8585",
                            indexAxis: 'y'
                          }
                        ],
                      
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true
                              }
                            }
                          }
                  }}/>

                      </div>
                  </div>
              
                
                
                <div className="col-xl-6 col-md-6">
                      <div className="card-body">
                    
                      <Bar data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:chartPhasesPlatformNpsPreLaunch?.labels,
                                    datasets: [
                                      {
                                        label: 'Conversation',
                                        data:chartPhasesPlatformNpsPreLaunch?.distribution,
                                        borderColor:"#a4dcff",
                                        backgroundColor: "#a4dcff",
                                      },
                                      {
                                        label: 'NPS',
                                        data:chartPhasesPlatformNpsPreLaunch?.data_nps,
                                        borderColor:"#ffc6a3",
                                        backgroundColor:"#ffc6a3",
                                      }
                                    ],
                                    options: {
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                   
                                        }
                                      }
                                    },
                                  }}/>

                      </div>
                  
                </div>
                </div>
                </div>
          </div>
          </div>
</section>


<section className='home__section4'>
<div className="row">
<div className="col-xl-12"><div className="card">
<div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Launch</div> <div className="total__conv__count">Total conversation vol:  {chartPhasesDriversLaunchTotal["total conversations"]}
</div> <div className="total__conv__count">  NPS:   {chartPhasesDriversLaunchTotal?.NPS}%</div></div>
<div className="row">

                <div className="col-xl-6 col-md-6">
                      <div className="card-body">
                    
                  <Bar data={{
                      responsive: true,
                      labels:chartPhasesDriversLaunch?.labels,
                        datasets: [{
                            label : 'Positive',
                            data: chartPhasesDriversLaunch?.positive_percentage,
                            borderWidth: 1,
                            borderColor:"#bcd8a8",
                            backgroundColor: "#bcd8a8",
                            indexAxis: 'y'
                          },
                          {
                            label : 'Negative',
                            data: chartPhasesDriversLaunch?.negative_percentage,
                            borderWidth: 1,
                            borderColor:"#ff8585",
                            backgroundColor:"#ff8585",
                            indexAxis: 'y'
                          }
                        ],
                      
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true
                              }
                            }
                          }
                  }}/>

                      </div>
               
                </div>
                
                
                <div className="col-xl-6 col-md-6">
                 
              
                      <div className="card-body">
                    
                      <Bar data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:chartPhasesPlatformNpsLaunch?.labels,
                                    datasets: [
                                      {
                                        label: 'Conversation',
                                        data:chartPhasesPlatformNpsLaunch?.distribution,
                                        borderColor:"#a4dcff",
                                        backgroundColor: "#a4dcff",
                                      },
                                      {
                                        label: 'NPS',
                                        data:chartPhasesPlatformNpsLaunch?.data_nps,
                                        borderColor:"#ffc6a3",
                                        backgroundColor:"#ffc6a3",
                                      }
                                    ],
                                    options: {
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                    
                                        }
                                      }
                                    },
                                  }}/>

                      </div>
                 
                </div>

                </div>

                </div></div>
          </div>
</section>



<section className='home__section4'>
<div className="row">
<div className="col-md-12">
<div className="card">
<div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Post Launch</div> <div className="total__conv__count">Total conversation vol:  {chartPhasesDriversPostLaunchTotal["total conversations"]}
</div> <div className="total__conv__count">  NPS:   {chartPhasesDriversPostLaunchTotal.NPS}%</div></div>
  <div className="row">
                <div className="col-xl-6 col-md-6">
           
                 
                      <div className="card-body">
                    
                  <Bar data={{
                      responsive: true,
                      labels:chartPhasesDriversPostLaunch?.labels,
                        datasets: [{
                            label : 'Positive',
                            data: chartPhasesDriversPostLaunch?.positive_percentage,
                            borderWidth: 1,
                            borderColor:"#bcd8a8",
                            backgroundColor: "#bcd8a8",
                            indexAxis: 'y'
                          },
                          {
                            label : 'Negative',
                            data: chartPhasesDriversPostLaunch?.negative_percentage,
                            borderWidth: 1,
                            borderColor:"#ff8585",
                            backgroundColor:"#ff8585",
                            indexAxis: 'y'
                          }
                        ],
                      
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true
                              }
                            }
                          }
                  }}/>

                      </div>
                 
                </div>
                
                
                <div className="col-xl-6 col-md-6">
                      <div className="card-body">
                    
                      <Bar data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:chartPhasesPlatformNpsPostLaunch?.labels,
                                    datasets: [
                                      {
                                        label: 'Conversation',
                                        data:chartPhasesPlatformNpsPostLaunch?.distribution,
                                        borderColor:"#a4dcff",
                                        backgroundColor: "#a4dcff",
                                      },
                                      {
                                        label: 'NPS',
                                        data:chartPhasesPlatformNpsPostLaunch?.data_nps,
                                        borderColor:"#ffc6a3",
                                        backgroundColor:"#ffc6a3",
                                      }
                                    ],
                                    options: {
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                     
                                        }
                                      }
                                    },
                                  }}/>

                      </div>
              
                </div>
                </div>
</div>
                </div>
          </div>
</section> */}


{/* end for client request */}





{/* 
<section className='home__section4'>
<div className="row">

                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Download  Data </div>
                      <div className="card-body mycs__table">
                    <div className='table-responsive'>
                      <table className="table table-striped">
  <thead>
    <tr>
      {downloadTabledataHeaders.map((item, index)=>(
        <>
        <th key={index} className="table-success">{item}</th>
        </>
      ))}
    </tr>
  </thead>
  <tbody>


{downloadTabledata.map((item, index)=>(
        <>
        <td key={index} className="table-success">{item}</td>
        </>
      ))}


    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table>
</div>

                
                      </div>
                  </div>
                </div>
              

          </div>
</section> */}









        </section>



        <footer className="pc-footer">
    <div className="footer-wrapper container-fluid">
      <div className="row">
        <div className="col my-1">
          <p className="m-0">Cheil South West Asia</p>
        </div>
        
      </div>
    </div>
  </footer> 

  
        </>
    )
}

export default Engagement