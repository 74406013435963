import { Lock, UserPlus } from 'react-feather';
import { Link } from "react-router-dom"
const LoginButton = () =>{
    return(
        <>
         <li className="pc-item">
        <Link to="/login" className="pc-link">
            <span className="pc-micon"><Lock/></span>
            <span className="pc-mtext">Login</span>
          </Link>
        </li>
        <li className="pc-item">
        <Link to="/signup" className="pc-link">
            <span className="pc-micon"><UserPlus/></span>
            <span className="pc-mtext">Register</span>
          </Link>
        </li>
        </>
    )
}


export default LoginButton;
