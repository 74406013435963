import { useForm } from 'react-hook-form';
import axiosInstance from '../services/AuthInterceptor'
import {ToastContainer, toast } from 'react-toastify';


const DeleteUser = () =>{

    const { register, handleSubmit, reset, formState: { errors } } = useForm();


    const ondeleteUserSubmit = async (data) => {
            console.log(data)

            const confirmBox = window.confirm(
                "Do you want to delete user"
              )
              if (confirmBox === true) {
                await axiosInstance.post(`${process.env.REACT_APP_OTP_API_AUTH_URL}delete_user`, data)
                    .then(function (response) {
                      if(response && response.status === 200){
                        console.log(response)
                       toast.info(response.data.message)
                        reset()
                      }else{
                        console.log(response)
                      }
                    })
                    .catch(function (error) {
                      console.log('error message')
                       toast.error(error.response.data)
                       reset()
                    });
     
              }

       
    };


    return(
        <>


<ToastContainer
    	position="top-right"
    	/>


         <div className="home__page">
        <div className="adduserform__outer">
       <div className="row">
              
              <div className="col-xl-12 col-md-12">
               <div className="card">
                   <div className="card-body ">
        <div className="user__regstrationform">
            <form onSubmit={handleSubmit(ondeleteUserSubmit)} autoComplete="off">
                <div className="row justify-content-center">
                    <div className="col-md-4">
                    <div className="form-group">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input type="email" autoComplete="off" className="form-control" placeholder="Email address" name='username' {...register("username", {required: true, pattern: /^\S+@\S+$/i})}/>
                    </div>
                    <p className="error__field">
                {errors.username && errors.username.type === "required" && <span>This is required</span>} {errors.username && errors.username.type === "pattern" && <span>Please enter correct email</span>}
            </p>
                    </div>
              
                    <div className="col-md-4 text-left" style={{paddingTop:'30px'}}><button type="submit" className="btn btn-dark text-left">Submit</button></div>
                </div>
            </form>
        </div>
       </div>
       </div>
       </div>
       </div>
       </div>
       </div>
        
        </>
    )
}


export default DeleteUser