import { ArrowUp, Calendar } from 'react-feather';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import {Chart as ChartJs, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend} from 'chart.js/auto';
import { Line, Bar } from 'react-chartjs-2';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../services/AuthInterceptor'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import WordCloud from 'react-d3-cloud';

ChartJs.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend)

const Innerpage = () =>{
 
  const convCheckboxRfe = useRef(null)
  const dayCheckboxRfe = useRef(null)


  const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const currentData = new Date().toJSON().slice(0,10)

    const defaultDeviceValue = {
      "device": "Q6B6",
      "id": 1
  };
    const defaultDurationStartValue = {
      "date_start": "L-30",
      "id": 16
  };
    const defaultDurationEndValue = {
      "date_end": "L+3",
      "id": 49
  };
    const defaultPlatformValue = {
      "id": 2,
      "platform": "TWITTER"
  };



    const [getOnLoadDeviceList, SetonLoadDeviceList] = useState([defaultDeviceValue])
    const [getDurationStartList, SetDurationStartList] =   useState([defaultDurationStartValue])
    const [getDurationEndList, SetDurationEndList] =   useState([defaultDurationEndValue])
    const [getPlatformList, SetPlatformList] =   useState([defaultPlatformValue])

  const [deviceValueChange, setdeviceValueChange] = useState(false)
  const [durationStartChange, setDurationStartChange] = useState(false)
  const [durationEndChange, setDurationEndChange] = useState(false)
  const [platformChange, setplatformChange] = useState(false)

  const [selectedDevice, SetselectedDevice]= useState({})
  const [selectedStartDate, SetselectedStartDate]= useState({})
  const [selectedEndDate, SetselectedEndDate]= useState({})
  const [selectedplatform, Setselectedplatform]= useState({})

    const [ConversationsTrend, setConversationsTrend] = useState([])
    const [CountConversations, setCountConversations] = useState()
  
    const [sentimentDatanegative, setsentimentDatanegative] = useState({
      "share":"",
      "volume":""
    })
    const [sentimentDataneutral, setsentimentDataneutral] = useState({})
    const [sentimentDatapositive, setsentimentDatapositive] = useState({})
    

    const [overallNps, setoverallNps] = useState()
    const [conversationsChart, setConversationsChart] = useState({})


    const [convonloadValue, setConvonloadValue]=useState()
    const [dayonloadValue, setDayonloadValue]=useState()


    const [ChartPlatformNps, setChartPlatformNps] =useState({})

    

    
const onloaData = async() =>{
    try {
      await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}data_chart1`,{}
    )
      .then(function (response) {
        if(response && response.status == '200'){
          setConversationsTrend(response.data.data.datasets)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
       console.log('error message')
      });
    } catch (error) {
      console.log(error)
      toast.error(error)
    }
} 


const onLoadDeviceList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}device_list`,{}
  )
    .then(function (response) {
      if(response && response.status == '200'){
         // console.log(response.data.data)
          SetonLoadDeviceList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}


const onLoadDurationStartList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list`,{}
  )
    .then(function (response) {
      if(response && response.status == '200'){
          //console.log(response.data.data)
          SetDurationStartList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}

const onLoadDurationEndList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list`,{}
  )
    .then(function (response) {
      if(response && response.status == '200'){
         // console.log(response.data.data)
          SetDurationEndList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}

const onLoadPlatformList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}platform_list`,{}
  )
    .then(function (response) {
      if(response && response.status == '200'){
         // console.log(response.data.data)
          SetPlatformList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}

const loadData ={
  "device": defaultDeviceValue.id,
  "date_start": defaultDurationStartValue.id,
  "date_end": defaultDurationEndValue.id,
  "platform": defaultPlatformValue.id
}

const {platform, ...rest } = loadData;




const getCountConversations = async (countData) =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}count_conversations`,{
      "api_data":{...countData}
    }
  )
    .then(function (response) {
      if(response && response.status == '200'){
        //console.log(response?.data.data.convo_count)
        setCountConversations(response?.data.data.convo_count)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}


const getoverallNPS = async (countData) =>{

  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}overall_nps`,{
      "api_data":{...countData}
    }
  )
    .then(function (response) {
      if(response && response.status == '200'){
       console.log(response)
        setoverallNps(response.data.nps_data.nps_vector)
        setsentimentDatanegative(response.data.sentiment_data.negative)
        setsentimentDatapositive(response.data.sentiment_data.positive)
        setsentimentDataneutral(response.data.sentiment_data.neutral)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}



const getChartConversations = async (countData, freValue) =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_conversations`,{
      "api_data":{...countData,"frequency": freValue} ,      
    }
  )
    .then(function (response) {
      if(response && response.status == '200'){
        console.log(response.data.data)
        setConversationsChart(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}



const getChartPlatformNps = async (countData) =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_platform_nps`,{
      "api_data":{...countData} ,      
    }
  )
    .then(function (response) {
      if(response && response.status == '200'){
        console.log(response)
        setChartPlatformNps(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}




useEffect(()=>{
  onloaData()
  onLoadDeviceList()
  onLoadDurationStartList()
  onLoadDurationEndList()
  onLoadPlatformList()
  getCountConversations(loadData)
  getoverallNPS(loadData)
  getChartConversations(loadData, dayCheckboxRfe.current.value)
 if(convCheckboxRfe.current){
  setConvonloadValue(convCheckboxRfe.current.value)
 }

 if(dayCheckboxRfe.current){
  setDayonloadValue(dayCheckboxRfe.current.value)
 }

 getChartPlatformNps(rest)
   

}, [])


// const handledatachange = (event) =>{
//   const { name, value } = event.target;
//   setFromData((prevFormData) => ({ ...prevFormData, [name]: value }));
// }


const handleDeviceChange = (event) =>{
  SetselectedDevice(event.target.value)
  setdeviceValueChange(true)
}

const handleDatestart = (event) =>{
  SetselectedStartDate(event.target.value)
  setDurationStartChange(true)
}
 
const handleDateend = (event) =>{
  SetselectedEndDate(event.target.value)
  setDurationEndChange(true)
}

const handlePlatform =(event) =>{
  Setselectedplatform(event.target.value)
  setplatformChange(true)
}


const handleChacboxChangeconv = (event) =>{
  setConvonloadValue(event.target.value)
  alert(event.target.value)
}

const handleChacboxChangeday = (event) =>{
  setDayonloadValue(event.target.value)
  getChartConversations(loadData, event.target.value)
}


const handleFormData = async (e) => {
  e.preventDefault()
  try {

    const finalDeviceValue = deviceValueChange ? selectedDevice : defaultDeviceValue.id
    const finalStartDateValue = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
    const finalEndDateValue = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
    const finalPlateformValue = platformChange ? selectedplatform : defaultPlatformValue.id

      console.log(finalDeviceValue)
      const formData = {
        "device": parseInt(finalDeviceValue),
        "date_start": parseInt(finalStartDateValue),
        "date_end": parseInt(finalEndDateValue),
        "platform": parseInt(finalPlateformValue)
    }


    const{platform, ...rest} = formData


      console.log(formData)
      getCountConversations(formData)
      getoverallNPS(formData)

      getChartPlatformNps(rest)

      //console.log(getOnLoadDeviceList)
  } catch (error) {
    console.log(error)
  }
};







    return(
        <>
        <ToastContainer
    	position="top-right"
    	/>



{/* <WordCloud data={data} /> */}






        <section className="home__page">

            <section className="page__hd">Pulse Dashboard</section>

            <section className="home__section1">
            <div className="row">
             <div className="col-xl-12 col-md-12">
              <div className="card">
                  <div className="card-body ">
                   <form onSubmit={handleFormData}>
                        <div className="row justify-content-between">
                        <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="campaignname">Select Device</label>
                                  <div className="input-group">
                                  <select className="form-select" id="campaignname" placeholder="Campaign name"  name='device'  onChange={handleDeviceChange} defaultValue={defaultDeviceValue} >
                                         {getOnLoadDeviceList.map((data,index)=>(
                                          <option key={data.id} value={data.id}>{data.device_name}</option>
                                         ))}
                                        </select>
                                    </div>

                            </div>
                          
                            <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="pc-datepicker-10">Date/Time</label>
                                

                          
                                  <div className="input-group date mycustomdate">
                                        {/* <input type="range" class="form-range"  id="start__date" placeholder="Start date"  name='startdate' step="1"  onChange={handledatachange}/> */}
                                        <select className="form-select" id="startdate" name='date_start' onChange={handleDatestart} defaultValue={defaultDurationStartValue}>

                                         {getDurationStartList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.duration_name}</option>
                                         ))}


                                        </select>
                                        <span className="input-group-text" max={currentData}>
                                        <Calendar color="#888" size="13"/>
                                        </span> 
                           
                                        <select className="form-select" id="enddate" name='date_end' onChange={handleDateend} defaultValue={defaultDurationEndValue}>
                                        {getDurationEndList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.duration_name}</option>
                                         ))}

                                        </select>
                                        {/* <input type="range" class="form-range" id="end__date" placeholder="End date" name='enddate' step="1" onChange={handledatachange} /> */}
                                    </div>

                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="devicename">Select Platform</label>
                               
                                <select className="form-select" id="selectplatform" placeholder="select platform"  name='platform'  onChange={handlePlatform} defaultValue={defaultPlatformValue}>
                                       {getPlatformList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.network_name}</option>
                                         ))}
                                        </select>
                               
                            </div>


                            <div className='form-group col-md-3 text-right' style={{paddingTop:'30px'}}>
                            <button type="submit" className="btn btn-light">Reset</button>
                            <button type="submit" className="btn btn-dark">Search</button>
                            </div>

                            
                        </div>

                      
                      </form>
                  </div>
              </div>
             </div>
      </div>
            </section>



            <section className="home__section21">
            <div className="row">

<div className="col-md-3 col-xl-3">
     <div className="card">
         <div className="card-body crad__new__design1">
             <h6 className="mb-4 newh6heading">Device Name</h6>
             <div className="row d-flex align-items-center">
             <div className="col-9">
                 <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">
                
                   $249.95</h3>
             </div>
         </div>
            
         </div>
     </div>
</div>


<div className="col-md-3 col-xl-3">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-4 newh6heading">Launch Date</h6>
         <div className="row d-flex align-items-center">
             <div className="col-9">
                 <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">
                
                   $249.95</h3>
             </div>
         </div>
     </div>
 </div>
</div>

<div className="col-md-3 col-xl-3">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-4 newh6heading">Conversations</h6>
         <div className="row d-flex align-items-center">
             <div className="col-9">
                 <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">{CountConversations}</h3>
             </div>
         </div>
    
 
     </div>
 </div>
</div>

<div className="col-md-3 col-xl-3">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-4 newh6heading">NPS</h6>
         <div className="row d-flex align-items-center">
             <div className="col-9">
                 <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">{overallNps}
                </h3>
             </div>
         </div>
  
      
     </div>
 </div>
</div>

</div>
            </section>





            <section className='home__section3'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Conversations Trend</div>
                      <div className="card-body">
                      
<div className="row justify-content-between radiofilter">
  <div className="col-md-6 d-flex">
  <div className="form-check">
 
  <label className="form-check-label" >
  <input className="form-check-input" name="conversations" ref={convCheckboxRfe} onChange={handleChacboxChangeconv} type="radio" value="Conversations" defaultChecked  />
  Conversations
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="conversations" onChange={handleChacboxChangeconv} type="radio" value="NPS Cumulative" />
  NPS Cumulative
  </label>
</div>
  </div>

  <div className="col-md-6 d-flex justify-content-end">
  <div className="form-check">
 
  <label className="form-check-label" >
  <input className="form-check-input" name="days" ref={dayCheckboxRfe} onChange={handleChacboxChangeday} type="radio" value="daily" defaultChecked  />
  Daily
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="days" onChange={handleChacboxChangeday}  type="radio" value="weekly" />
  Weekly
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="days" onChange={handleChacboxChangeday}  type="radio" value="monthly" />
  Monthly
  </label>
</div>
  </div>

  
 
</div>


                              <Line data={{
                                  responsive: true,
                                    labels: conversationsChart.labels,
                                    datasets: [{
                                      label: 'Conversations',
                                      data:convonloadValue === 'NPS Cumulative' ? conversationsChart.data_nps : conversationsChart.data_convo ,
                                      borderWidth: 1,
                                      borderColor:convonloadValue === 'NPS Cumulative' ? '#9ad0f5' : '#ffb1c1' ,
                                    }],
                                  options: {
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        beginAtZero: true
                                      }
                                    },
                                    plugins: {
                                      datalabels: {
                                          anchor: 'end', 
                                          align: 'end', 
                                          color: 'blue',
                                          font: {
                                              weight: 'bold',
                                          },
                                      }
                                  }
                                  }
                              }}/>
                          
                      </div>
                  </div>
                </div>
          </div>
</section>





<section className='home__section3'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Platform
                      </div>
                      <div className="card-body">

                      <Bar data={{
                                  responsive: true,
                                    labels: ChartPlatformNps.labels,
                                    datasets: [
                                      {
                                        type: 'bar',
                                        label: 'Conversations',
                                        data: ChartPlatformNps.data_convo,
                                        borderWidth: 1,
                                        backgroundColor:'#bfbfbf',
                                          borderColor:'#bfbfbf'
                                      },
                                      {
                                      type: 'bar',
                                      label: 'NPS',
                                      data: ChartPlatformNps.data_nps,
                                      borderWidth: 1,
                                       backgroundColor:'#ed7d31',
                                       borderColor:'#ed7d31'
                                    },
                                    
                                  ],
                                  options: {
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        position: 'top',
                                      },
                                      title: {
                                        display: true,
                                        text: 'Chart.js Bar Chart'
                                      }
                                    }
                                  }
                              }}/>

                      </div>
                  </div>
                </div>
          </div>
</section>







<section className='home__section3'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Conversation [Distribution by Platform]
                      </div>
                      <div className="card-body">
                    
                      <Line data={{
                                  responsive: true,
                                    labels: ChartPlatformNps.labels,
                                    datasets: [{
                                      type: 'bar',
                                      label: 'Galaxy Z Fold 6',
                                      data: ChartPlatformNps.distribution,
                                      borderWidth: 1
                                    }],
                                  
                                  options: {
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        beginAtZero: true
                                      }
                                    }
                                  }
                              }}/>

                      </div>
                  </div>
                </div>
          </div>
</section>





            <section className='home__section3'>
<div className="row">
<div className="col-xl-5 col-md-5">
<div className="card">
<div className="bd-example">
  <table className="table table-striped">
      <thead>
    <tr>
      <th scope="col">Total</th>
      <th scope="col">Volume</th>
      <th scope="col">Share</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>Positive</th>
      <td>{sentimentDatapositive.volume}</td>
      <td>{sentimentDatapositive.share}</td>
    </tr>
    <tr>
      <th>Negative</th>
      <td>{sentimentDatanegative.volume}</td>
      <td>{sentimentDatanegative.share}</td>
    </tr>
    <tr>
      <td>Neutral</td>
      <td>{sentimentDataneutral.volume}</td>
      <td>{sentimentDataneutral.share}</td>
    </tr> 
  </tbody>

  </table>
</div>
  </div>
  </div>
                <div className="col-xl-7 col-md-7">
                  <div className="card">
                      <div className="card-header bgnone">Mix chart</div>
                      <div className="card-body">
                    
                      <Bar data={{
                                  responsive: true,
                                  type: 'bar',
                                    labels: ['10 Jun', '11 Jun', '12 Jun', '13 Jun', '14 Jun', '15 Jun'],
                                    datasets: [
                                      {
                                        label: 'Dataset 1',
                                        data: [12, 24, 18, 24, 26, 30],
                                        backgroundColor:"red",
                                        stack: 'Stack 0',
                                      },
                                      {
                                        label: 'Dataset 2',
                                        data: [12, 24, 18, 24, 26, 30],
                                        backgroundColor: "blue",
                                        stack: 'Stack 0',
                                      },
                                      {
                                        label: 'Dataset 3',
                                        data: [12, 24, 18, 24, 26, 30],
                                        backgroundColor:"green",
                                        stack: 'Stack 0',
                                      },
                                    ],
                                  
                                  options: {
                                    plugins: {
                                      title: {
                                        display: true,
                                        text: 'Chart.js Bar Chart - Stacked'
                                      },
                                      datalabels: {
                                        display: true,
                                        color: "black"
                                      }
                                    },
                                    responsive: true,
                                    interaction: {
                                      intersect: false,
                                    },
                                    scales: {
                                      x: {
                                        stacked: true,
                                      },
                                      y: {
                                        stacked: true
                                      }
                                    }
                                  }
                              }}/>

                      </div>
                  </div>
                </div>
          </div>
</section>





<section className='home__section2'>
<div className="row">
          <div className="col-xl-6 col-md-6">
              <div className="card new__bg-color">
                  <div className="card-header border-0">
                      <h5 className="text-white">NPS</h5>
                  </div>
                  <div className="card-body"  style={{paddingLeft:25 + 'px', paddingRight:25 + 'px',}}>
                      <div className="earning-text mb-0">
                          <h3 className="mb-2 text-white f-w-300">XXX.XXXX % <i className="feather icon-arrow-up teal accent-3"></i></h3>
                          <span className="text-uppercase text-white d-block"> previous week : yyy.yyy % </span>
                      </div>
                      <div id="Widget-line-chart" className="WidgetlineChart2 ChartShadow">
                        <Line data={{
                                  responsive: true,
                                    labels: ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun'],
                                    datasets: [{
                                      label: '',
                                      data: [12, 24, 18, 24, 26, 30],
                                      borderWidth: 5,
                                      backgroundColor:'rgba(255,255,255,1)',
                                      borderColor: "#fff",
                                    }],
                              }} options={{
                                scales: {
                                  x:{
                                      grid: {
                                          display: false, 
                                      },
                                      ticks:{
                                        color: 'white',
                                    },
                                    border:{
                                      color: '#04a9f5',
                                    }
                                  },
                                  y:{
                                    display: false,
                                    borderColor: "#04a9f5",
                                      grid: {
                                          display: false,
                                          borderColor: "#04a9f5",
                                      },
                                  }
                              }
                              }}/>
                      </div>
                  </div>
              </div>

          </div>


           <div className="col-md-6 col-xl-6">
               <div className="row">

                 <div className="col-md-6 col-xl-6">
                      <div className="card">
                          <div className="card-body crad__new__design1">
                              <h6 className="mb-4 newh6heading">Total Conversation</h6>
                              <div className="row d-flex align-items-center">
                                  <div className="col-9">
                                      <h3 className="f-w-300 d-flex align-items-center m-b-0">
                                        <span className="ss__newspan f-30 m-r-10">
                                        <ArrowUp className="text-success f-30 m-r-10" size={30}/>
                                        </span>
                                        $249.95</h3>
                                  </div>

                                  <div className="col-3 text-end">
                                      <p className="m-b-0">67%</p>
                                  </div>
                              </div>
                              <div className='progress__outer__new'>
                              <div className="progress m-t-30" style={{height:7 + 'px'}}>
                                  <div className="progress-bar bg-brand-color-1" role="progressbar" style={{width:50 + '%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <div className='progress__outer1' style={{width:50 + '%'}}></div>
                              </div>
                          </div>
                      </div>
                </div>


               <div className="col-md-6 col-xl-6">
                  <div className="card">
                      <div className="card-body crad__new__design1">
                          <h6 className="mb-4 newh6heading">Positive Conversations</h6>
                          <div className="row d-flex align-items-center">
                              <div className="col-9">
                                  <h3 className="f-w-300 d-flex align-items-center m-b-0">
                                  <span className="ss__newspan f-30 m-r-10">
                                    <ArrowUp className="text-success f-30 m-r-10" size={30}/>
                                    </span>
                                    $249.95</h3>
                              </div>

                              <div className="col-3 text-end">
                                  <p className="m-b-0">67%</p>
                              </div>
                          </div>
                          
                          <div className='progress__outer__new'>
                          <div className="progress m-t-30"  style={{height:7 + 'px'}}>
                              <div className="progress-bar bg-brand-color-3" role="progressbar" style={{width:50 + '%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className='progress__outer1' style={{width:50 + '%'}}></div>
                          </div>
                      </div>
                  </div>
                </div>

               <div className="col-md-6 col-xl-6">
                  <div className="card">
                      <div className="card-body crad__new__design1">
                          <h6 className="mb-4 newh6heading">Neutral Conversations</h6>
                          <div className="row d-flex align-items-center">
                              <div className="col-9">
                                  <h3 className="f-w-300 d-flex align-items-center m-b-0">
                                    <span className="ss__newspan f-30 m-r-10"><ArrowUp className="text-success" size={30}/></span>$249.95</h3>
                              </div>

                              <div className="col-3 text-end">
                                  <p className="m-b-0">67%</p>
                              </div>
                          </div>
                          <div className='progress__outer__new'>
                          <div className="progress m-t-30"  style={{height:7 + 'px'}}>
                              <div className="progress-bar bg-brand-color-4" role="progressbar" style={{width:50 + '%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className='progress__outer1' style={{width:50 + '%'}}></div>
                      </div>
                  
                      </div>
                  </div>
                </div>

               <div className="col-md-6 col-xl-6">
                  <div className="card">
                      <div className="card-body crad__new__design1">
                          <h6 className="mb-4 newh6heading">Negative Conversations</h6>
                          <div className="row d-flex align-items-center">
                              <div className="col-9">
                                  <h3 className="f-w-300 d-flex align-items-center m-b-0">
                                  <span className="ss__newspan f-30 m-r-10">
                                    <ArrowUp className="text-success f-30 m-r-10" size={30}/>
                                    </span>
                                    $249.95</h3>
                              </div>

                              <div className="col-3 text-end">
                                  <p className="m-b-0">67%</p>
                              </div>
                          </div>
                          <div className='progress__outer__new'>
                          <div className="progress m-t-30" style={{height:7 + 'px'}}>
                              <div className="progress-bar bg-brand-color-5" role="progressbar" style={{width:50 + '%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div className='progress__outer1' style={{width:50 + '%'}}></div>
                      </div>
                       
                      </div>
                  </div>
                </div>

             </div>
        </div>
      </div>
</section>







<section className='home__section3'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Bar chart with 2</div>
                      <div className="card-body">
                    
                      <Bar data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels: ['10 Jun', '11 Jun', '12 Jun', '13 Jun', '14 Jun', '15 Jun'],
                                    datasets: [
                                      {
                                        label: 'Dataset 1',
                                        data:[12, 24, 18, 24, 26, 30],
                                        borderColor:"#3971b5",
                                        backgroundColor: "#3971b5",
                                      },
                                      {
                                        label: 'Dataset 2',
                                        data:[30, 24, 36, 40, 48, 20],
                                        borderColor:"#b83a37",
                                        backgroundColor:"#b83a37",
                                      }
                                    ],
                                    options: {
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                          text: 'Chart.js Bar Chart'
                                        }
                                      }
                                    },
                                  }}/>

                      </div>
                  </div>
                </div>
          </div>
</section>





<section className='home__section3'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Mix chart</div>
                      <div className="card-body">
                    
                      <Line data={{
                                  responsive: true,
                                    labels: ['L-10', 'L-10', 'L', 'L+1', 'L+2', 'L+3','L-10', 'L-10', 'L', 'L+1', 'L+2', 'L+3'],
                                    datasets: [{
                                      type: 'bar',
                                      label: 'Galaxy Z Fold 6',
                                      data: [12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3],
                                      borderWidth: 1
                                    }, {
                                      type: 'line',
                                      label: 'Galaxy Z Fold 5',
                                      data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                                      borderWidth: 1
                                    }],
                                  
                                  options: {
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        beginAtZero: true
                                      }
                                    }
                                  }
                              }}/>

                      </div>
                  </div>
                </div>
          </div>
</section>

<section className='home__section4'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header">NPS Trend new ssss
                      </div>
                      <div className="card-body">
                      
<Line data={{
  responsive: true,
  labels: ['10 Jun', '11 Jun', '12 Jun', '13 Jun', '14 Jun', '15 Jun'],
    datasets: [{
      type: 'line',
      borderColor: "#35a2bf",
      borderWidth: 2,
      radius: 0,
      data: [12, 24, 18, 24, 26, 30],
      label: 'Total',
    },
    {
      type: 'line',
      borderColor: "#3971b5",
      borderWidth: 2,
      radius: 0,
      data: [30, 24, 36, 40, 48, 20],
      label: 'Daily NPS',
    },
    {
      type: 'line',
      borderColor: "#b83a37",
      borderWidth: 2,
      radius: 0,
      data: [10, 14, 22, 45, 88, 17],
      label: 'Cumulative NPS',
    }],
  options: {
    animation:false,
    interaction: {
      intersect: false
    },
    plugins: {
      legend: false
    },
    scales: {
      x: {
        type: 'linear'
      }
    }
  }
}}/>
                          
                      </div>
                  </div>
                </div>
          </div>
</section>



<section className='home__section4'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header">NPS Trend
                      </div>
                      <div className="card-body">
                      
                              <Line data={{
                                  responsive: true,
                                    labels: ['L-10', 'L-10', 'L', 'L+1', 'L+2', 'L+3','L-10', 'L-10', 'L', 'L+1', 'L+2', 'L+3'],
                                    datasets: [{
                                      label: 'Galaxy Z Fold 4',
                                      data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                                      borderWidth: 1,
                                       borderColor:'green',
                                       backgroundColor:'green'
                                    }, {
                                      label: 'Galaxy Z Fold 5',
                                      data: [23, 10, 2, 3, 4, 15, 23, 10, 2, 3, 4, 15],
                                      borderWidth: 1,
                                       borderColor:'blue',
                                        backgroundColor:'blue'
                                    },
                                    {
                                      label: 'Galaxy Z Fold 6',
                                      data: [20, 25, 15, 13, 21, 44, 20, 25, 15, 13, 21, 44],
                                      borderWidth: 1,
                                      borderColor:'red',
                                        backgroundColor:'red'
                                    }
                                  ],
                                  
                                  options: {
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        beginAtZero: true
                                      }
                                    }
                                  }
                              }}/>
                          
                      </div>
                  </div>
                </div>
          </div>
</section>

<section className='home__section5'>
<hr/>
<div className="title mb-3"> Comparative Overview </div>
</section>

<section className='home__section6'>
<div className="row">
              <div className="col-xl-6 col-md-6">
                  <div className="card">

                      <div className="card-header bgnone">Social Sentiment</div>
                      <div className="card-body">
                          <div>
                          <Bar data={{
                                  responsive: true,
                                  labels: ['positive', 'negative', 'Neutral'],
                                    datasets: [{
                                        label : '# of Conversation',
                                        data: [22324, 1432],
                                        borderWidth: 1
                                      }],
                                  
                                      options: {
                                        maintainAspectRatio: false,
                                        scales: {
                                          y: {
                                            beginAtZero: true
                                          }
                                        }
                                      }
                              }}/>
                            </div>

                      </div>
                  </div>

              </div>

              <div className="col-xl-6 col-md-6">
                  <div className="card">
                      <div className="card-header bgnone">Social Sentiment</div>
                      <div className="card-body">
                          <div>
                          <Bar data={{
                                  responsive: true,
                                  labels: ['positive', 'negative', 'Neutral'],
                                    datasets: [{
                                        label : '# of Conversation',
                                        data: [12323, 600],
                                        borderWidth: 1
                                      }],
                                  
                                      options: {
                                        maintainAspectRatio: false,
                                        scales: {
                                          y: {
                                            beginAtZero: true
                                          }
                                        }
                                      }
                              }}/>
                            </div>
                       </div>
                    </div>
                </div>
              </div>

</section>


<section className='home__section5'>
<hr/>
<div className="title mb-3"> Drivers of Sentiment </div>
</section>


<section className='section7'>
<div className="row">
    <div className="col-xl-12 col-md-12">
    <div className="card">
   <div className='row'>
    <div className="col-xl-6 col-md-6">
    <div className="card-body">
    <Bar data={{
                      responsive: true,
                      labels: ['Design', 'Processor', 'AI', 'Camera', 'Display', 'Battery', 'Price'],
                        datasets: [{
                            label : 'Positive',
                            data: [902, 529, 472, 347, 299, 100, 58],
                            borderWidth: 1,
                            backgroundColor : 'blue',
                            indexAxis: 'y'
                          }],
                      
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true
                              }
                            }
                          }
                  }}/>
        </div>
        </div>    
        <div className="col-xl-6 col-md-6">
        <div className="card-body">
        <Bar data={{
                      responsive: true,
                      labels: ['Design', 'Processor', 'AI', 'Camera', 'Display', 'Battery', 'Price'],
                        datasets: [{
                            label : 'Negative',
                            data: [902, 529, 472, 347, 299, 100, 58],
                            borderWidth: 1,
                            backgroundColor : 'red',
                            indexAxis: 'y'
                          }],
                      
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true
                              }
                            }
                          }
                  }}/>
        </div>
        </div> 
        </div>

    </div>
    </div>
    </div>
</section>


<section className='home__section5'>
<hr/>
<div className="title mb-3"> Compartive Overview ( Sprinklr )</div>
</section>



<section className='home__section8'>
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone">Galaxy Z Fold6 | Z Flip6 Social Conversations = 0.6x iPhone15 Series Duration: (L-30 to L+41)</div>
                      <div className="card-body">
                         
                      <Line data={{
                                  responsive: true,
                                  labels: ['L-10', 'L-10', 'L', 'L+1', 'L+2', 'L+3'],
                                    datasets: [{
                                      label: 'Galaxy Z Fold 6',
                                      data: [12, 19, 3, 5, 2, 3],
                                      borderWidth: 1
                                    }, {
                                      label: 'Galaxy Z Fold 5',
                                      data: [23, 10, 2, 3, 4, 15],
                                      borderWidth: 1
                                    }],
                                  
                                  options: {
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        beginAtZero: true
                                      }
                                    }
                                  }
                              }}/>
                      </div>
                  </div>
                </div>
            </div>
</section>

<section className='section__home9'>
<div className="row">
              <div className="col-xl-6 col-md-6">
                  <div className="card">
                      <div className="card-header bgnone">Galaxy Z Fold6 | Z Flip6: Social Sentiment</div>
                      <div className="card-body">
                      <Bar data={{
                                  responsive: true,
                                  labels: ['positive', 'negative', 'Neutral'],
                                    datasets: [{
                                        label : '# of Conversation',
                                        data: [22324, 1432],
                                        borderWidth: 1
                                      }],
                                  
                                      options: {
                                        maintainAspectRatio: false,
                                        scales: {
                                          y: {
                                            beginAtZero: true
                                          }
                                        }
                                      }
                              }}/>
                      </div>
                  </div>
              </div>
              <div className="col-xl-6 col-md-6">
                  <div className="card">
                      <div className="card-header bgnone">iPhone15 Series: Social Sentiment</div>
                         <div className="card-body ">
                         <Bar data={{
                                  responsive: true,
                                  labels: ['positive', 'negative', 'Neutral'],
                                    datasets: [{
                                        label : '# of Conversation',
                                        data: [22324, 1432],
                                        borderWidth: 1
                                      }],
                                  
                                      options: {
                                        maintainAspectRatio: false,
                                        scales: {
                                          y: {
                                            beginAtZero: true
                                          }
                                        }
                                      }
                              }}/>
                       </div>
                    </div>
                </div>
              </div>
</section>
        </section>



        <footer className="pc-footer">
    <div className="footer-wrapper container-fluid">
      <div className="row">
        <div className="col my-1">
          <p className="m-0">Cheil South West Asia</p>
        </div>
        
      </div>
    </div>
  </footer> 

  
        </>
    )
}

export default Innerpage