import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import HomeLayout from './Layouts/HomeLayout';
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import TwiterComment from "./pages/TwiterComment";
import RouterProtection from "./auth/RouterProtection";
import Profile from "./pages/user/Profile";
import axios from 'axios';
import Userlisting from "./admin/Userlisting";
import AddUser from "./admin/AddUser";
import Innerpage from "./pages/Innerpage";
import ModelComparison from "./pages/ModelComparison";
import { HashRouter } from "react-router-dom";
import axiosInstance from './services/AuthInterceptor'
import DeleteUser from "./admin/DeleteUser";
import Unauthorized from "./pages/user/Unauthorized";
import Engagement from "./pages/Engagement";


const  App = () => {
  return (
 <BrowserRouter>
      <Routes>
        <Route  element={<HomeLayout />}>
        {/* <Route exact index element={<Home/>} /> */}
          <Route   path="/" element={<RouterProtection Component={Home} />} />
          <Route   path="comparison" element={<RouterProtection Component={ModelComparison} />} />
          {/* <Route  exact path="comments" element={<RouterProtection Component={TwiterComment} />} /> */}
          <Route   path="about" element={<RouterProtection Component={Innerpage} />} />
          <Route   path="engagement" element={<RouterProtection Component={Engagement} />} />
          <Route   path="profile" element={<RouterProtection Component={Profile} />} />
          <Route  path="admin/list" element={<RouterProtection Component={Userlisting} />} />
          <Route   path="admin/adduser" element={<RouterProtection Component={AddUser} />} />
          <Route   path="admin/delete" element={<RouterProtection Component={DeleteUser} />} />
          <Route   path="unauthorized" element={<RouterProtection Component={Unauthorized} />} />
          <Route   path="*" element={<NoPage />} />
        </Route>
        <Route   index path="/login" element={<Login />} />
        <Route   path="/signup" element={<Signup />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
