import { useEffect, useState } from "react"

const Unauthorized = () =>{
    const [timeleft, settimeleft]  =useState(0)
    const [isRunning, setisRunning] = useState(false)

useEffect(()=>{
    let timer;
    if(isRunning && timeleft > 0){
        timer = setTimeout(()=>{
            settimeleft(timeleft - 1)
        }, 1000)
    }else if(timeleft === 0 && isRunning){
        setisRunning(false)
        alert();
    }


    return ()=> clearTimeout(timer) 


},[timeleft, isRunning])

const startTimer = () =>{
    settimeleft(120)
    setisRunning(true)
}

    return(
        <>
        <h2>You are Unauthorized user</h2>
        <button onClick={startTimer} disabled={isRunning}>
            {isRunning ? `Resend otp in ${timeleft} Second` : 'send opt'}
        </button>
        </>
    )
}


export default Unauthorized