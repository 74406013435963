import { ArrowUp, Calendar } from 'react-feather';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState, useMemo, Suspense} from 'react';
import {Chart as ChartJs, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend} from 'chart.js/auto';
import { Line, Bar } from 'react-chartjs-2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../services/AuthInterceptor'
import { useForm } from 'react-hook-form';
import WordCloud from 'react-d3-cloud';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import DownloadData from './DownloadData';
import * as XLSX from "xlsx";
import { useNavigate } from 'react-router-dom';
import Datanotfound from '../components/Datanotfound';
import Loadding from '../components/Loadding';
import { saveAs } from 'file-saver';

//ChartJs.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend)
ChartJs.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ChartDataLabels)
const Home = () =>{
 
  const convCheckboxRfe = useRef(null)
  const dayCheckboxRfe = useRef(null)

  const navigate = useNavigate()


    const currentData = new Date().toJSON().slice(0,10)

    const defaultDeviceValue = {
      "device": "Q6B6",
      "id": 1
  };
    const defaultDurationStartValue = {
      "date_start": "L-30",
      "id": 16
  };
    const defaultDurationEndValue = {
      "date_end": "L+3",
      "id": 49
  };
    const defaultPlatformValue = {
      "id": 100,
      "platform": "All"
  };

  const defaultsubdrivers = {
    "id": 9,
    "label": "Ai"
}



  var resultasaSas;


    const [getOnLoadDeviceList, SetonLoadDeviceList] = useState([defaultDeviceValue])
    const [getDurationStartList, SetDurationStartList] =   useState([defaultDurationStartValue])
    const [getDurationEndList, SetDurationEndList] =   useState([defaultDurationEndValue])
    const [getPlatformList, SetPlatformList] =   useState([defaultPlatformValue])


  
    const [getSubdrivers, SetSubdrivers] =   useState([defaultsubdrivers])

  const [deviceValueChange, setdeviceValueChange] = useState(false)
  const [durationStartChange, setDurationStartChange] = useState(false)
  const [durationEndChange, setDurationEndChange] = useState(false)
  const [platformChange, setplatformChange] = useState(false)

  const [getSubdriversChange, setgetSubdriversChange] = useState(false)

  const [getSubdriversLodding, setSubdriversLodding] = useState(false)

  const [getSubdriversdatablank, setSubdriversdatablank] = useState(false)


  const [selectedDevice, SetselectedDevice]= useState({})
  //const [selectedDevicename, setselectedDevicename] = useState()
  const [selectedStartDate, SetselectedStartDate]= useState({})
  const [selectedEndDate, SetselectedEndDate]= useState({})
  const [selectedplatform, Setselectedplatform]= useState({})

  const [selectedSubdrive, SetselectedSubdrive]= useState({})

  const [getSubdriversdata, SetgetSubdriversdata]= useState({})




  const [deviceId, setDeviceId] = useState('')



  const [selectedplatformName, SetselectedplatformName]= useState('')

    const [ConversationsTrend, setConversationsTrend] = useState([])
    const [CountConversations, setCountConversations] = useState()

    const [launchDate, setLaunchDate] = useState()
  
    const [sentimentDatanegative, setsentimentDatanegative] = useState({
      "share":"",
      "volume":""
    })
    const [sentimentDataneutral, setsentimentDataneutral] = useState({})
    const [sentimentDatapositive, setsentimentDatapositive] = useState({})
    

    const [overallNps, setoverallNps] = useState()
    const [conversationsChart, setConversationsChart] = useState({})
    const [conversationsChartAlert, setConversationsChartAlert] = useState("")

    const [convonloadValue, setConvonloadValue]=useState()
    const [dayonloadValue, setDayonloadValue]=useState()


    const [ChartPlatformNps, setChartPlatformNps] =useState({})

    const [SentimentTrend, setSentimentTrend] = useState([])
    const [SentimentTrendAlert, setSentimentTrendAlert] = useState("")

    const [DriversOfSentiment, setDriversOfSentiment]= useState([])

    const [chartPhasesDriversPreLaunch, setChartPhasesDriversPreLaunch] = useState({})
    const [chartPhasesDriversLaunch, setChartPhasesDriversLaunch] = useState({})
    const [chartPhasesDriversPostLaunch, setChartPhasesDriversPostLaunch] = useState({})


    
    const [chartPhasesDriversPreLaunchTotal, setChartPhasesDriversPreLaunchTotal] = useState({})
    const [chartPhasesDriversLaunchTotal, setChartPhasesDriversLaunchTotal] = useState({})
    const [chartPhasesDriversPostLaunchTotal, setChartPhasesDriversPostLaunchTotal] = useState({})
    

    const [chartPhasesPlatformNpsPreLaunch, setchartPhasesPlatformNpsPreLaunch] = useState({})
    const [chartPhasesPlatformNpsLaunch, setchartPhasesPlatformNpsLaunch] = useState({})
    const [chartPhasesPlatformNpsPostLaunch, setchartPhasesPlatformNpsPostLaunch] = useState({})

    const [downloadTabledataHeaders, setdownloadTabledataHeaders] = useState([])
const [downloadTabledata, setdownloadTabledata] = useState([])



const[finalFormData, setfinalFormData]= useState({})

const [topicList, setTopicList] =useState([])
const [selectedTopic, setSelectedTopic] = useState('6')
const [getTopicdata, setTopicData] = useState([])
const [getTopicHeader, setTopicHeader] = useState([])
const [loadinghome, setloadinghome] = useState(false)

const [sentimentdata, Setsentimentdata] = useState([])

const [searchText, SetSearchText] = useState("")

const[showPopup, setshowPopup] = useState(false)




const onLoadDeviceList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}device_list`,{}
  )
    .then(function (response) {
      if(response && response.status === 200){
         console.log(response.data.data)
          SetonLoadDeviceList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
      console.log(error.message)
     
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}


const onLoadDurationStartList = async () =>{
  try {
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list`,{}
  )
    .then(function (response) {
      if(response && response.status === 200){
          console.log(response.data.data)
          SetDurationStartList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
 
    });
  } catch (error) {
    console.log(error)
    toast.error(error)
  }
}

const onLoadDurationEndList = async () =>{
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list`,{}
  )
    .then(function (response) {
      if(response && response.status === 200){
         // console.log(response.data.data)
          SetDurationEndList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
 
}

const onLoadPlatformList = async () =>{

    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}platform_list`,{}
  )
    .then(function (response) {
      if(response && response.status === 200){
         //console.log(response.data.data)
          SetPlatformList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });

}





var loadData ={
  "device": defaultDeviceValue.id,
  "date_start": defaultDurationStartValue.id,
  "date_end": defaultDurationEndValue.id,
  "platform": defaultPlatformValue.id
}

const {platform, ...rest } = loadData;


const getlaunchDate = async (deviceId) =>{

    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}launch_data`,{
      "api_data":{...deviceId}
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
         console.log(response.data.data)
         setLaunchDate(response.data.data.launch_date)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
 
}

const getCountConversations = async (countData) =>{
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}count_conversations`,{
      "api_data":{...countData,"search_text":searchText}
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        //console.log(response?.data.data.convo_count)
        setCountConversations(response?.data.data.convo_count)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
      
    });
 
}


const getoverallNPS = async (countData) =>{

    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}overall_nps`,{
      "api_data":{...countData,"search_text":searchText}
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
      // console.log(response)
        setoverallNps(response.data.nps_data.nps_vector)
        setsentimentDatanegative(response.data.sentiment_data.negative)
        setsentimentDatapositive(response.data.sentiment_data.positive)
        setsentimentDataneutral(response.data.sentiment_data.neutral)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
     
    });

}



const getChartConversations = async (countData, freValue) =>{
  console.log(countData, freValue)
  setloadinghome(true)
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_conversations`,{
      "api_data":{...countData,"frequency": freValue, "search_text":searchText} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
       console.log(response.data.data)
        setConversationsChart(response.data.data)
        setloadinghome(false)
        setConversationsChartAlert(response.data['alert'])
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
     
    });

}



const getChartPlatformNps = async (countData) =>{
 
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_platform_nps`,{
      "api_data":{...countData, "search_text":searchText} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response)
        setChartPlatformNps(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
 
}


const getSentimentTrend = async (countData, freValue) =>{
 
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_sentiment_trend`,{
      "api_data":{...countData,"frequency": freValue, "search_text":searchText} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response.data.data)
        setSentimentTrend(response.data.data)
        setSentimentTrendAlert(response.data["alert"])
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
 
}

const getDriversOfSentiment = async (countData) =>{
  setloadinghome(true)
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_drivers_of_sentiment`,{
      "api_data":{...countData,"search_text":searchText} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response.data.data)
        setDriversOfSentiment(response.data.data)
        console.log(response.data.sub_driver_list.id)
        console.log(response.data.sub_driver_array)
       SetSubdrivers(response.data.sub_driver_array)
        setloadinghome(false)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
     
    });
 
}


const getSubDriversOfSentiment = async (countData) =>{
  setloadinghome(false)
  setSubdriversLodding(true)
  console.log(countData)
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_drivers_of_sentiment_sub`,{
      "api_data":{...countData,"search_text":searchText} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        if(response.data.data !== ""){
          console.log(response.data.data)
          setloadinghome(false)
          SetgetSubdriversdata(response.data.data)
          setSubdriversLodding(false)
          setSubdriversdatablank(false)
        }else{
          setSubdriversdatablank(true)
        }
       
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
     setSubdriversdatablank(true)
     
    });
 
}




const getChartPhasesDrivers = async (countData) =>{
  setloadinghome(true)
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_phases_drivers`,{
      "api_data":{...countData,"search_text":searchText} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
        console.log(response.data.data)
        console.log(response.data.data.pre_launch)
        setChartPhasesDriversPreLaunch(response.data.data.pre_launch)
        setChartPhasesDriversPreLaunchTotal(response.data.data.pre_launch.totals)
        setChartPhasesDriversLaunch(response.data.data.launch)
        setChartPhasesDriversLaunchTotal(response.data.data.launch.totals)
        setChartPhasesDriversPostLaunch(response.data.data.post_launch)
        setChartPhasesDriversPostLaunchTotal(response.data.data.post_launch.totals)
        setloadinghome(false)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    });
 
}

const getChartPhasesPlatformNps = async (countData) =>{
 
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}chart_phases_platform_nps`,{
      "api_data":{...countData,"search_text":searchText} ,      
    }
  )
    .then(function (response) {
      if(response && response.status === 200){
       //console.log(response.data.data)
        setchartPhasesPlatformNpsPreLaunch(response.data.data.pre_launch)
        setchartPhasesPlatformNpsLaunch(response.data.data.launch)
        setchartPhasesPlatformNpsPostLaunch(response.data.data.post_launch)
       
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
     
    });
 
}

const downloadData = async (countData) =>{
  setloadinghome(true)
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}data_download`,{
      "api_data":{...countData} ,      
    }
    
  )
    .then(function (response) {
      if(response && response.status === 200){
       console.log(response.data.data)
       console.log(response.data.data.filename)
        const workbookn = XLSX.utils.book_new();
        const worksheetn = XLSX.utils.json_to_sheet(response.data.data.data);
      
        XLSX.utils.book_append_sheet(workbookn, worksheetn, "commentdata");
        // customize header names
        XLSX.utils.sheet_add_aoa(worksheetn, [
          response.data.data.headers
        ]);
      
        XLSX.writeFile(workbookn, response.data.data.filename, { compression: true });
        setloadinghome(false)


      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
  
}



const handleDownloadexcel = () =>{

  const finalDeviceValueBAc = deviceValueChange ? selectedDevice : defaultDeviceValue.id
  const finalStartDateValueBAc = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
  const finalEndDateValueBAc = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
  const finalPlateformValueBAc = platformChange ? selectedplatform : defaultPlatformValue.id
 
  const formDataBAc = {
      "device": parseInt(finalDeviceValueBAc),
      "date_start": parseInt(finalStartDateValueBAc),
      "date_end": parseInt(finalEndDateValueBAc),
      "platform": parseInt(finalPlateformValueBAc)
  }

  downloadData(formDataBAc)
}







const getCommentTopic = async () =>{
 
    await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}topic_list`,{} 
  )
    .then(function (response) {
      if(response && response.status === 200){
       console.log(response.data.data)
       setTopicList(response.data.data)
      }else{
        console.log(response)
      }
    })
    .catch(function (error) {
     console.log('error message')
    
    });
  
}


const getDataDownloadTopic = async (countData, freValue) =>{
  console.log(countData, freValue)
  setloadinghome(true)
  await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}data_download_topics`,{
  "api_data":{...countData,"topic": freValue} ,      
  } 
  )
  .then(function (response) {
  if(response && response.status === 200){
   console.log(response.data.data)
  //  console.log(response.data.data)
  //  setTopicData(response.data.data)
  //  setTopicHeader(response.data.data.headers)
 
if(response.data.data){
  const workbookns = XLSX.utils.book_new();
  const worksheetns = XLSX.utils.json_to_sheet(response.data.data.negative_comments);
  const worksheetns1 = XLSX.utils.json_to_sheet(response.data.data.positive_comments);

  XLSX.utils.book_append_sheet(workbookns, worksheetns, "negative_comments");
  XLSX.utils.book_append_sheet(workbookns, worksheetns1, "positive_comments");
  // customize header names
  XLSX.utils.sheet_add_aoa(worksheetns, [
    response.data.data.headers
  ]);

  XLSX.writeFile(workbookns, response.data.data.filename, { compression: true });
  setloadinghome(false)
}else{
  setloadinghome(false)
}

  }else{
    console.log(response)
  }
  })
  .catch(function (error) {
  console.log('error message')
  
  });
  
  }




const handleChangeTopic = (event) =>{
setSelectedTopic(event.target.value)
}


const handleTopicDataDownload = () =>{
  const finalDeviceValueBAta = deviceValueChange ? selectedDevice : defaultDeviceValue.id
  const finalStartDateValueBAta = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
  const finalEndDateValueBAta = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
  const finalPlateformValueBAta = platformChange ? selectedplatform : defaultPlatformValue.id
 
  const formDataBAta = {
      "device": parseInt(finalDeviceValueBAta),
      "date_start": parseInt(finalStartDateValueBAta),
      "date_end": parseInt(finalEndDateValueBAta),
      "platform": parseInt(finalPlateformValueBAta),
  }


  getDataDownloadTopic(formDataBAta, selectedTopic)


}



const haldleHints = () =>{
  setshowPopup(true)
}

const closeHints = () =>{
  setshowPopup(false)
}


 

useEffect(()=>{
 // onloaData()


 const finalDeviceValueB = deviceValueChange ? selectedDevice : defaultDeviceValue.id
 const finalStartDateValueB = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
 const finalEndDateValueB = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
 const finalPlateformValueB = platformChange ? selectedplatform : defaultPlatformValue.id


 const finalgetSubdriverValueB = getSubdriversChange ? selectedSubdrive : defaultsubdrivers.id


 const formDataB = {
     "device": parseInt(finalDeviceValueB),
     "date_start": parseInt(finalStartDateValueB),
     "date_end": parseInt(finalEndDateValueB),
     "platform": parseInt(finalPlateformValueB)
 }

 const formDatasub = {
  "device": parseInt(finalDeviceValueB),
  "date_start": parseInt(finalStartDateValueB),
  "date_end": parseInt(finalEndDateValueB),
  "platform": parseInt(finalPlateformValueB),
  "sub_driver":parseInt(finalgetSubdriverValueB)
}

 const{platform, ...rest} = formDataB

  onLoadDeviceList()
  onLoadDurationStartList()
  onLoadDurationEndList()
  onLoadPlatformList()

  getlaunchDate({"device": parseInt(finalDeviceValueB)})


  getCountConversations(formDataB)
  getoverallNPS(formDataB)
  getChartConversations(formDataB, dayCheckboxRfe.current?.value)
  getSentimentTrend(formDataB, dayCheckboxRfe.current?.value)



 if(convCheckboxRfe.current){
  setConvonloadValue(convCheckboxRfe.current.value)
 }

 if(dayCheckboxRfe.current){
  setDayonloadValue(dayCheckboxRfe.current.value)
 }

 getChartPlatformNps(rest)
 getDriversOfSentiment(formDataB)  

 getSubDriversOfSentiment(formDatasub)


 getChartPhasesDrivers(formDataB)
 getChartPhasesPlatformNps(rest)
 //getDataDownloadTopic(formDataB, selectedTopic)


 return(()=>{
  console.log('clearning up')
  
})





}, [])

// defaultDeviceValue.id, defaultDurationEndValue.id, defaultDurationStartValue.id, defaultPlatformValue.id, deviceValueChange, durationEndChange, durationStartChange, platformChange, selectedDevice, selectedEndDate, selectedStartDate, selectedTopic,selectedplatform


// const handledatachange = (event) =>{
//   const { name, value } = event.target;
//   setFromData((prevFormData) => ({ ...prevFormData, [name]: value }));
// }



const handlesubdrivers = (event) =>{
  event.preventDefault();
  SetselectedSubdrive(event.target.value)
  setgetSubdriversChange(true)


  const finalDeviceValueB = deviceValueChange ? selectedDevice : defaultDeviceValue.id
  const finalStartDateValueB = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
  const finalEndDateValueB = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
  const finalPlateformValueB = platformChange ? selectedplatform : defaultPlatformValue.id
  const finalgetSubdriverValueB = event.target.value
 

  const formDatasub = {
   "device": parseInt(finalDeviceValueB),
   "date_start": parseInt(finalStartDateValueB),
   "date_end": parseInt(finalEndDateValueB),
   "platform": parseInt(finalPlateformValueB),
   "sub_driver":parseInt(finalgetSubdriverValueB)
 }


  getSubDriversOfSentiment(formDatasub)







  }




const handleDeviceChange = (event) =>{
  SetselectedDevice(event.target.value)
  setdeviceValueChange(true)
 
}







const changestartDuration = async (duradionId) =>{
  //alert(duradionId)
      await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list_get_right_list`,{"api_data": {
      "duration_id": JSON.parse(duradionId)
    }
  }
    )
      .then(function (response) {
        if(response && response.status === 200){
            console.log(response.data.data)
            SetDurationEndList(response.data.data)
            console.log(getDurationEndList)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
       console.log('error message')
       toast.error(error)
   
      });
   
  }




const handleDatestart = (event) =>{
  SetselectedStartDate(event.target.value)
  setDurationStartChange(true)
  setDeviceId(event.target.value)
 // alert();

  changestartDuration(event.target.value)
  //alert(event.target.value)
}

const changeEndDuration = async (duradionId) =>{
 // alert(duradionId)
      await axiosInstance.post(`${process.env.REACT_APP_PRODUCTION_API_URL}duration_list_get_left_list`,{"api_data": {
      "duration_id": JSON.parse(duradionId)
    }
  }
    )
      .then(function (response) {
        if(response && response.status === 200){
            console.log(response.data.data)
            SetDurationStartList(response.data.data)
            console.log(getDurationEndList)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
       console.log('error message')
       toast.error(error)
   
      });
   
  }



 
const handleDateend = (event) =>{
  SetselectedEndDate(event.target.value)
  setDurationEndChange(true)
  console.log(getDurationEndList)
  changeEndDuration(event.target.value)
}

const handlePlatform =(event) =>{
  Setselectedplatform(event.target.value)
  setplatformChange(true)
  console.log(event)
  const ssp = event.target.options[event.target.selectedIndex]
  console.log(ssp.getAttribute('data-ntework'))
  SetselectedplatformName(ssp.getAttribute('data-ntework'))
}


const handlefuzzymatch = (event) =>{
 //console.log(event.target.value)
 SetSearchText(event.target.value)
}





const handleChacboxChangeconv = (event) =>{
  setConvonloadValue(event.target.value)
  //alert(event.target.value)
  //alert(dayonloadValue)



  const finalDeviceValueACO = deviceValueChange ? selectedDevice : defaultDeviceValue.id
  const finalStartDateValueACO = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
  const finalEndDateValueACO = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
  const finalPlateformValueACO = platformChange ? selectedplatform : defaultPlatformValue.id


  const formDataACO = {
    "device": parseInt(finalDeviceValueACO),
    "date_start": parseInt(finalStartDateValueACO),
    "date_end": parseInt(finalEndDateValueACO),
    "platform": parseInt(finalPlateformValueACO)
}

  if(event.target.value === 'NPS Daily'){
    getChartConversations(formDataACO, "daily")
    getSentimentTrend(formDataACO,  "daily")
  }
  else{
    getChartConversations(formDataACO, dayonloadValue)
    getSentimentTrend(formDataACO, dayonloadValue)
  }
}

const handleChacboxChangeday = (event) =>{
  setDayonloadValue(event.target.value)


//alert(event.target.value)


  const finalDeviceValueA = deviceValueChange ? selectedDevice : defaultDeviceValue.id
    const finalStartDateValueA = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
    const finalEndDateValueA = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
    const finalPlateformValueA = platformChange ? selectedplatform : defaultPlatformValue.id

    const formDataA = {
        "device": parseInt(finalDeviceValueA),
        "date_start": parseInt(finalStartDateValueA),
        "date_end": parseInt(finalEndDateValueA),
        "platform": parseInt(finalPlateformValueA)
    }



  getChartConversations(formDataA, event.target.value)
  getSentimentTrend(formDataA,  event.target.value)
}


const handleFormData = async (e) => {
  e.preventDefault()
  try {

    const finalDeviceValue = deviceValueChange ? selectedDevice : defaultDeviceValue.id
    const finalStartDateValue = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
    const finalEndDateValue = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
    const finalPlateformValue = platformChange ? selectedplatform : defaultPlatformValue.id

    const finalgetSubdriverValueBs = getSubdriversChange ?  selectedSubdrive : defaultsubdrivers.id

    const formData = {
        "device": parseInt(finalDeviceValue),
        "date_start": parseInt(finalStartDateValue),
        "date_end": parseInt(finalEndDateValue),
        "platform": parseInt(finalPlateformValue)
    }


    const formDatasubn = {
      "device": parseInt(finalDeviceValue),
      "date_start": parseInt(finalStartDateValue),
      "date_end": parseInt(finalEndDateValue),
      "platform": parseInt(finalPlateformValue),
      "sub_driver":parseInt(finalgetSubdriverValueBs)
  }


    const{platform, ...rest} = formData



    getSubDriversOfSentiment(formDatasubn)


   
  getCommentTopic()
  const checkTopic = document.querySelector('input[name="topic"]:checked')
  if(checkTopic){
    setSelectedTopic(checkTopic.value)
  }
 setfinalFormData(formData)




  getlaunchDate({"device": parseInt(finalDeviceValue)})
  //console.log(formData)
  getCountConversations(formData)
  getoverallNPS(formData)

  getChartPlatformNps(rest)

  getDriversOfSentiment(formData)

  getChartPhasesDrivers(formData)
getChartPhasesPlatformNps(rest)

//getDataDownloadTopic(formData, selectedTopic)


if(convonloadValue === 'NPS Daily'){
getChartConversations(formData, 'daily')
getSentimentTrend(formData, 'daily')
}else{
getChartConversations(formData, dayonloadValue)
getSentimentTrend(formData, dayonloadValue)
}









      //console.log(getOnLoadDeviceList)
  } catch (error) {
    console.log(error)
  }
};



//tabbing
const [activeTab, setActiveTab] = useState('tab-1');

const handleClickTab = (event) => {
  setActiveTab(event.target.id);


  if(event.target.id == 'tab-4'){
    const finalDeviceValueBs = deviceValueChange ? selectedDevice : defaultDeviceValue.id
    const finalStartDateValueBs = durationStartChange ? selectedStartDate : defaultDurationStartValue.id
    const finalEndDateValueBs = durationEndChange ? selectedEndDate : defaultDurationEndValue.id
    const finalPlateformValueBs = platformChange ? selectedplatform : defaultPlatformValue.id

    const formDataBs = {
        "device": parseInt(finalDeviceValueBs),
        "date_start": parseInt(finalStartDateValueBs),
        "date_end": parseInt(finalEndDateValueBs),
        "platform": parseInt(finalPlateformValueBs)
    }
    const{platforms, ...rest} = formDataBs
   
  
   // downloadData(formDataBs)
   setfinalFormData(formDataBs)
  
  
   getCommentTopic()
   const checkTopic = document.querySelector('input[name="topic"]:checked')
   if(checkTopic){
     setSelectedTopic(checkTopic.value)
   }
   //getDataDownloadTopic(formDataBs, selectedTopic)
  
  
  }

};






    return(
        <>
        <ToastContainer
    	position="top-right"
    	/>





{/* <WordCloud data={data} /> */}







        <section className="home__page">

    

            <section className="home__section1">
                     {/* <section className="page__hd">Pulse Dashboard</section> */}
            <div className="row">
              
             <div className="col-xl-12 col-md-12">
              <div className="card">
                  <div className="card-body ">
                  <div className='page__heading__new'><h1>Social listening</h1></div>
                   <form onSubmit={handleFormData}>
                        <div className="row justify-content-between">
                        <div className="form-group col-md-2">
                                <label className="form-label" htmlFor="campaignname">Select Device</label>
                                  <div className="input-group">
                                  <select className="form-select" id="campaignname" placeholder="Campaign name"  name='device'  onChange={handleDeviceChange} defaultValue={defaultDeviceValue} >
                                         {getOnLoadDeviceList && getOnLoadDeviceList.map((data,index)=>(
                                          <option key={data.id} value={data.id}>{data.device_name}</option>
                                         ))}
                                        </select>
                                    </div>

                            </div>
                          
                            <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="pc-datepicker-10">Date/Time</label>
                                

                          
                                  <div className="input-group date mycustomdate">
                                        {/* <input type="range" className="form-range"  id="start__date" placeholder="Start date"  name='startdate' step="1"  onChange={handledatachange}/> */}
                                        <select className="form-select" id="startdate" name='date_start' onChange={handleDatestart} defaultValue={defaultDurationStartValue}>

                                         {getDurationStartList && getDurationStartList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.duration_name}</option>
                                         ))}


                                        </select>
                                        <span className="input-group-text" max={currentData}>
                                        <Calendar color="#888" size="13"/>
                                        </span> 
                           
                                        <select className="form-select" id="enddate" name='date_end' onChange={handleDateend} defaultValue={defaultDurationEndValue}>
                                        {getDurationEndList && getDurationEndList.map((data, index)=>(
                                           <option key={data.id} value={data.id}>{data.duration_name}</option>
                                         ))}

                                        </select>
                                        {/* <input type="range" className="form-range" id="end__date" placeholder="End date" name='enddate' step="1" onChange={handledatachange} /> */}
                                    </div>

                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="devicename">Select Platform</label>
                               
                                <select className="form-select" id="selectplatform" placeholder="select platform"  name='platform'  onChange={handlePlatform} defaultValue={defaultPlatformValue}>
                                       {getPlatformList && getPlatformList.map((data, index)=>(
                                           <option key={data.id} value={data.id} data-ntework={data.network_name}>{data.network_name}</option>
                                         ))}
                                        </select>
                               
                            </div>

                            <div className="form-group col-md-3">
                                <label className="form-label" htmlFor="devicename">Fuzzy match search  <span className='hints' onClick={haldleHints}>i</span></label>
                               
                             <input type="text"  className="form-control inputtext"  id="search_text" placeholder="Type..."  name='search_text' onChange={handlefuzzymatch} />
                              
                            </div>


                            <div className='form-group col-md-1 text-right' style={{paddingTop:'30px'}}>
                            {/* <button type="submit" className="btn btn-light">Reset</button> */}
                            <button type="submit" className="btn btn-dark text-right">Search</button>
                            </div>

                            
                        </div>

                      
                      </form>
                  </div>
              </div>
             </div>
      </div>
            </section>

         

            <section className='sectiontabbing'>
            <div className="tabs">
         
          <button
            className={`tab-button ${activeTab === 'tab-1' ? 'active' : ''}`}
            id="tab-1"
            onClick={handleClickTab}
          >
           Conversations
          </button>
          <button
            className={`tab-button ${activeTab === 'tab-2' ? 'active' : ''}`}
            id="tab-2"
            onClick={handleClickTab}
          >
            Drivers of Sentiment
          </button>
          <button
            className={`tab-button ${activeTab === 'tab-3' ? 'active' : ''}`}
            id="tab-3"
            onClick={handleClickTab}
          >
           Phase Wise Analysis 
          </button>


          <button
            className={`tab-button ${activeTab === 'tab-4' ? 'active' : ''}`}
            id="tab-4"
            onClick={handleClickTab}
          >
           Reports 
          </button>


        </div>
        <div className="tabs-content">
            {/* start tab1*/}
          <div className={`tab-page ${activeTab === 'tab-1' ? 'active' : ''}`}>
            
          <section className="home__section21">

<div className="row">
  <div className="col-md-6">
  <div className="row">

<div className="col-md-6 col-xl-6">
     {/* <div className="card">
         <div className="card-body crad__new__design1">
             <h6 className="mb-3 newh6heading">Device Name</h6>
             <div className="row d-flex align-items-center">
             <div className="col-9">
                 <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">{deviceValueChange ? selectedDevice : defaultDeviceValue.id}</h3>
             </div>
         </div>
            
         </div>
     </div> */}
</div>


<div className="col-md-12 col-xl-12">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-3 newh6heading text-center">Launch Date</h6>
         <div className="row d-flex align-items-center">
             <div className="col-12">
                 <h3 className=" m-b-0 boldfont text-center">{launchDate}</h3>
             </div>
         </div>
     </div>
 </div>
</div>

<div className="col-md-6 col-xl-6">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-3 newh6heading">Conversations</h6>
         <div className="row d-flex align-items-center">
             <div className="col-9">
             <Suspense fallback={<p>Loading</p>}>
              {CountConversations && CountConversations ? <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">{CountConversations}</h3> : <Datanotfound/>}
              </Suspense>
                 
             </div>
         </div>
    
 
     </div>
 </div>
</div>

<div className="col-md-6 col-xl-6">
 <div className="card">
     <div className="card-body crad__new__design1">
         <h6 className="mb-3 newh6heading">NPS</h6>
         <div className="row d-flex align-items-center">
             <div className="col-9">
              {overallNps && overallNps ? <h3 className="f-w-300 d-flex align-items-center m-b-0 boldfont">{overallNps}</h3> : <Datanotfound/>}
                 
             </div>
         </div>
  
      
     </div>
 </div>
</div>

</div>
  </div>
  <div className="col-md-6">
<div className='row'>
<div className="col-xl-12 col-md-12">
<div className="card">
<div className="bd-example">
  <table className="table table-striped">
      <thead>
    <tr>
      <th scope="col">Total</th>
      <th scope="col">Volume</th>
      <th scope="col">Share</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>Positive</th>
      <td>{sentimentDatapositive && sentimentDatapositive ? sentimentDatapositive?.volume : <Datanotfound/>}</td>
      <td>{sentimentDatapositive && sentimentDatapositive ? sentimentDatapositive?.share :  <Datanotfound/>}</td>
    </tr>
    <tr>
      <th>Negative</th>
      <td>{sentimentDatanegative && sentimentDatanegative ? sentimentDatanegative?.volume : <Datanotfound/>}</td>
      <td>{sentimentDatanegative && sentimentDatanegative ? sentimentDatanegative?.share : <Datanotfound/>}</td>
    </tr>
    <tr>
      <th>Neutral</th>
      <td>{sentimentDataneutral && sentimentDataneutral ? sentimentDataneutral?.volume : <Datanotfound/>}</td>
      <td>{sentimentDataneutral && sentimentDataneutral ? sentimentDataneutral?.share : <Datanotfound/>}</td>
    </tr> 
  </tbody>

  </table>
</div>
  </div>
  </div>
</div>

  </div>
</div>




            </section>

            <div className="home__section21 home__section22">
<div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone d-flex  justify-content-between"><span>Conversations Trend  </span> <span style={{"color":"#000", "font-size":"13px"}}>{conversationsChartAlert}</span>
                         {/* {dayonloadValue} */}
                         </div>
                      <div className="card-body">
                      
<div className="row justify-content-between radiofilter">


{/* <div className="col-md-6 d-flex">
<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="conversations" ref={convCheckboxRfe} onChange={handleChacboxChangeconv} type="radio" value="Conversations" defaultChecked  />
Conversations
</label>
</div>
<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="conversations" onChange={handleChacboxChangeconv} type="radio" value="NPS Daily" />
NPS Daily
</label>
</div>
</div> */}

<div className="col-md-6 d-flex">
<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="conversations" ref={convCheckboxRfe} onChange={handleChacboxChangeconv} type="radio" value="Conversations" checked={convonloadValue === "Conversations"}  />
Conversations
</label>
</div>
<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="conversations" onChange={handleChacboxChangeconv} type="radio" value="NPS Daily" checked={convonloadValue === "NPS Daily"} />
NPS Daily
</label>
</div>


<div className="form-check">
<label className="form-check-label" >
<input className="form-check-input" name="conversations" onChange={handleChacboxChangeconv} type="radio" value="NPS Cumulative" checked={convonloadValue === "NPS Cumulative"} />
NPS Cumulative
</label>
</div>


</div>






 
{convonloadValue && convonloadValue === 'NPS Daily' ? '' : 
  <div className="col-md-6 d-flex justify-content-end">
  <div className="form-check">
 
  <label className="form-check-label" >
  <input className="form-check-input" name="days" ref={dayCheckboxRfe} onChange={handleChacboxChangeday} type="radio" value="daily" checked={dayonloadValue === "daily"}  />
  Daily
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="days" onChange={handleChacboxChangeday}  type="radio" value="weekly" checked={dayonloadValue === "weekly"} />
  Weekly
  </label>
</div>
<div className="form-check">
  <label className="form-check-label" >
  <input className="form-check-input" name="days" onChange={handleChacboxChangeday}  type="radio" value="monthly"  checked={dayonloadValue === "monthly"}  />
  Monthly
  </label>
</div>
  </div>

}

</div>
<div>



{loadinghome ? <Loadding/> : 
    <Line height="60vw" data={{
        responsive: true,
          labels: conversationsChart?.labels,
          datasets: [{
            label: convonloadValue,
            //data:convonloadValue === 'NPS Daily' ? conversationsChart?.data_nps : conversationsChart?.data_convo ,
            data:convonloadValue === 'NPS Daily' ? conversationsChart?.data_nps : convonloadValue === 'Conversations' ? conversationsChart?.data_convo : conversationsChart?.cumu_nps,
            borderWidth: 2,
            borderColor:convonloadValue === 'NPS Daily' ? '#7086a7' : '#ffb1c1' ,
            backgroundColor:convonloadValue === 'NPS Daily' ? '#7086a7' : '#ffb1c1' ,
            datalabels: {
              display:false,
            }
          }],
        
        options: {
          maintainAspectRatio: true,
          legend: {
            display: false
        },
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            datalabels: {
                display: false,
            },
            font: {
              family:'SamsungOne'
            },
        }
        }
    }}/>
  }
</div>
</div>
</div>
</div>
</div>
            </div>

            <section className='home__section3'>
<div className="row">

                <div className="col-xl-12 col-md-12">
                  <div className="card">
                      <div className="card-header bgnone d-flex justify-content-between"><span>Sentiment Trend</span>
                        <span style={{'color':'#000', 'font-size':'13px'}}>{SentimentTrendAlert && SentimentTrendAlert}</span>
                      </div>
                      <div className="card-body">
                    
                      <Bar height="60vw" data={{
                                  responsive: true,
                                  type: 'bar',
                                    labels: SentimentTrend?.labels,
                                    datasets: [
                                      {
                                        label: 'Positive',
                                        data:SentimentTrend?.nps_positive_perc,
                                        backgroundColor:"#bcd8a8",
                                        stack: 'Stack 0',
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          formatter: function(value,) {
                                            //return `${value }%`
                              
                                            if(value !== '0'){
                                              return `${value }%`
                                             }else{
                                              return ``
                                             }
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'8px'
                                      }
                                        }
                                      },
                                      {
                                        label: 'Neutral',
                                        data: SentimentTrend?.nps_neutral_perc,
                                        backgroundColor: "#808080",
                                        stack: 'Stack 0',
                                        datalabels: {
                                          color: '#fff',
                                          align: 'center',
                                          anchor: 'center',
                                          formatter: function(value,) {
                                            if(value !== '0'){
                                              return `${value }%`
                                             }else{
                                              return ``
                                             }
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'8px'
                                      }
                                        }
                                      },
                                      {
                                        label: 'Negative',
                                        data: SentimentTrend?.nps_negative_perc,
                                        backgroundColor:"#ff8585",
                                        stack: 'Stack 0',
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          formatter: function(value,) {
                                           // return `${value }%`
                                           if(value !== '0'){
                                            return `${value }%`
                                           }else{
                                            return ``
                                           }
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px',
                                      },
                                     
                                        }
                                      },
                                      
                                     
                                    ],
                                  
                                  options: {
                                    scales: {
                                      x: {
                                          ticks: {
                                              maxRotation: 90,
                                              minRotation: 90
                                          }
                                      }
                                  },
                                    plugins: {
                                      title: {
                                        display: true,
                     
                                      },
                                      datalabels: {
                                        display: true,
                                        color: "#fff",
                                        // formatter: (value) => `${value} %`
                                      },
                                        font: {
                                          family:'SamsungOne',
                                            size:'10px'
                                    },
                                    },
                                    responsive: true,
                                    interaction: {
                                      intersect: false,
                                    },
                                    scales: {
                                      x: {
                                        ticks: {
                                          maxRotation: 90,
                                          minRotation: 90
                                      },
                                        stacked: true,
                                        font: {
                                          family:'SamsungOne',
                                    }
                                      },
                                      y: {
                                        stacked: true,
                                        ticks:{
                                          beginAtZero:true,
                                          max:100,
                                          font: {
                                            family:'SamsungOne'
                                      }
                                        }
                                      }
                                    }
                                  }
                              }}/>

                      </div>
                  </div>
                </div>
          </div>
            </section>

            <section className='home__section3'>
<div className="row">
                <div className="col-xl-6 col-md-6">
                  <div className="card">
                      <div className="card-header bgnone">Conversation [Distribution by Platform]
                      </div>
                      <div className="card-body">
                    
                      <Line  data={{
                                  responsive: true,
                                    labels: ChartPlatformNps?.labels,
                                    datasets: [{
                                      type: 'bar',
                                      label: 'Conversation',
                                      data: ChartPlatformNps?.distribution,
                                      borderWidth: 1,
                                      backgroundColor:'#a4dcff',
                                      borderColor:'#a4dcff',
                                      datalabels: {
                                        color: '#000',
                                        align: 'center',
                                        anchor: 'center',
                                        formatter: function(value,) {
                                          return `${value }%`
                                        },
                                        font: {
                                          family:'SamsungOne'
                                        },
                                      }
                                      
                                    }],
                                  
                                  options: {
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        beginAtZero: true,
                                        font: {
                                          family:'SamsungOne'
                                        },
                                      }
                                      
                                    }
                                  },
                                  plugins: {
                                    font: {
                                      family:'SamsungOne'
                                    },
                                }
                              }}/>

                      </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="card">
                      <div className="card-header bgnone">NPS [Distribution by Platform]
                      </div>
                      <div className="card-body">

                      <Bar data={{
                                  responsive: true,
                                    labels: ChartPlatformNps?.labels,
                                    datasets: [
                                      // {
                                      //   type: 'bar',
                                      //   label: 'Conversations',
                                      //   data: ChartPlatformNps?.data_convo,
                                      //   borderWidth: 1,
                                      //   backgroundColor:'#a4dcff',
                                      //     borderColor:'#a4dcff'
                                      // },
                                      {
                                      type: 'bar',
                                      label: 'NPS',
                                      data: ChartPlatformNps?.data_nps,
                                      borderWidth: 1,
                                       backgroundColor:'#ffc6a3',
                                       borderColor:'#ffc6a3',
                                       datalabels: {
                                        color: '#000',
                                        align: 'center',
                                        anchor: 'center',
                                        formatter: function(value,) {
                                          return `${value }%`
                                        },
                                        font: {
                                          family:'SamsungOne',
                                    }
                                      }
                                    },
                                    
                                  ],
                                  options: {
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        position: 'top',
                                      },
                                      title: {
                                        display: true,
                                  
                                      },
                                      font: {
                                        family:'SamsungOne'
                                  }
                                    }
                                  }
                              }}/>

                      </div>
                  </div>
                </div>





          </div>
            </section>
            
          </div>
          {/* end tab1*/}


            {/* start tab2*/}
          <div className={`tab-page ${activeTab === 'tab-2' ? 'active' : ''}`}>

    {loadinghome ? <Loadding/> :      
          <section className='home__section3'>
<div className="row">
                <div className="col-xl-6 col-md-6">
                  <div className="card">
                      <div className="card-header bgnone">Drivers of Sentiment </div>
                      <div className="card-body">
                    
                      <Bar data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels: DriversOfSentiment?.labels,
                                    datasets: [
                                      {
                                        label: 'Positive',
                                        data:DriversOfSentiment?.positive_percentage,
                                        borderColor:"#bcd8a8",
                                        backgroundColor: "#bcd8a8",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          formatter: function(value,) {
                                            if(value > 10){
                                              return `${value }%`
                                            }else{
                                              return ``
                                            }
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'12px'
                                      }
                                        }
                                      },
                                      {
                                        label: 'Negative',
                                        data:DriversOfSentiment?.negative_percentage,
                                        borderColor:"#ff8585",
                                        backgroundColor:"#ff8585",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          formatter: function(value) {
                                            if(value > 10){
                                              return `${value }%`
                                            }else{
                                              return ``
                                            }
                                          },
                                          font: {
                                            family:'SamsungOne',
                                          size:'12px'
                                      }
                                        }
                                      }
                                    ],
                                    options: {
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                      
                                        },
                                        font: {
                                          family:'SamsungOne'
                                    }
                                      }
                                    },
                                  }}/>

                      </div>
                  </div>
                </div>



                <div className="col-xl-6 col-md-6">
                  <div className="card">
                    <div className="card-header bgnone card__inner"><span>Sub-Drivers </span>  <select className="form-select" id="Sub-Drivers" placeholder="Sub Drivers"  name='subdrivers'  onChange={handlesubdrivers} defaultValue={defaultsubdrivers} >
                                         {getSubdrivers && getSubdrivers.map((data,index)=>(
                                          <option key={index} value={data.id}>{data.label}</option>
                                         ))}
                                        </select></div>
                  
          
                      
                      <div className="card-body">
                      
                      
              { getSubdriversdatablank ? 'Data not found'
            : getSubdriversLodding ? 'Please wait while loading.'
            :  <Bar data={{
                            responsive:true,
                            type: 'bar',
                            labels: getSubdriversdata.labels,
                            datasets: [
                              {
                                label: 'Positive',
                                data:getSubdriversdata.positive_count,
                                borderColor:"#bcd8a8",
                                backgroundColor: "#bcd8a8",
                                datalabels: {
                                  color: '#000',
                                  align: 'center',
                                  anchor: 'center',
                                  rotation: -90,
                                  formatter: function(value,) {
                                    return `${value }`
                                  },
                                  font: {
                                    family:'SamsungOne',
                                    size:'12px'
                              }
                                }
                              },
                              {
                                label: 'Negative',
                                data:getSubdriversdata.negative_count,
                                borderColor:"#ff8585",
                                backgroundColor:"#ff8585",
                                datalabels: {
                                  color: '#000',
                                  align: 'center',
                                  anchor: 'center',
                                  rotation: -90,
                                  formatter: function(value,) {
                                    return `${value }`
                                  },
                                  font: {
                                    family:'SamsungOne',
                                    size:'12px'
                              }
                                }
                              }
                            ],
                            options: {
                              responsive: true,
                              plugins: {
                                legend: {
                                  position: 'top',
                                },
                                title: {
                                  display: true,
                            
                                },
                                font: {
                                  family:'SamsungOne'
                            }
                              }
                            },
                          }}/>
                     
                        }

                    
                     
                    
                      </div>
                  </div>
                </div>
               
          </div>
</section>
}   

          </div>
  {/* end tab2*/}

          <div className={`tab-page ${activeTab === 'tab-3' ? 'active' : ''}`}>


{loadinghome ? <Loadding/> : 
          <section className='home__section3 home__section31'>
<div className="row">
                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Pre Launch </div> | <div className="total__conv__count"> conversation vol:  {chartPhasesDriversPreLaunchTotal && chartPhasesDriversPreLaunchTotal["total conversations"]}
                  </div> | <div className="total__conv__count">  NPS:   {chartPhasesDriversPreLaunchTotal?.NPS}%</div></div>
                      <div className="card-body">
                    
                      <Bar  height="250vh" data={{
                      responsive: true,
                      labels:chartPhasesDriversPreLaunch?.labels,
                        datasets: [{
                            label : 'Positive',
                            data: chartPhasesDriversPreLaunch?.positive_percentage,
                            borderWidth: 1,
                            borderColor:"#bcd8a8",
                            backgroundColor: "#bcd8a8",
                            indexAxis: 'y',
                            datalabels: {
                              color: '#000',
                              align: 'center',
                              anchor: 'center',
                              rotation: 0,
                              formatter: function(value,) {
                                if(value > 5){
                                  return `${value }%`
                                 }else{
                                  return ``
                                 }
                              },
                              font: {
                                family:'SamsungOne',
                                size:'10px'
                          }
                            }
                          },
                          {
                            label : 'Negative',
                            data: chartPhasesDriversPreLaunch?.negative_percentage,
                            borderWidth: 1,
                            borderColor:"#ff8585",
                            backgroundColor:"#ff8585",
                            indexAxis: 'y',
                            datalabels: {
                              color: '#000',
                              align: 'center',
                              anchor: 'center',
                              rotation: 0,
                              formatter: function(value,) {
                                if(value > 5){
                                  return `${value }%`
                                 }else{
                                  return ``
                                 }
                              },
                              font: {
                                family:'SamsungOne',
                                size:'10px'
                          }
                            }
                          }
                        ],
                      
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true,
                                font: {
                                  family:'SamsungOne'
                            }
                              }
                            }
                          }
                  }}/>

                      </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Launch</div> | <div className="total__conv__count"> conversation vol:  {chartPhasesDriversLaunchTotal && chartPhasesDriversLaunchTotal["total conversations"]}
                  </div> | <div className="total__conv__count">  NPS:   {chartPhasesDriversLaunchTotal?.NPS}%</div></div>
                      <div className="card-body">
                    
                      <Bar  height="250vh" data={{
                      responsive: true,
                      labels:chartPhasesDriversLaunch?.labels,
                        datasets: [{
                            label : 'Positive',
                            data: chartPhasesDriversLaunch?.positive_percentage,
                            borderWidth: 1,
                            borderColor:"#bcd8a8",
                            backgroundColor: "#bcd8a8",
                            indexAxis: 'y',
                            datalabels: {
                              color: '#000',
                              align: 'center',
                              anchor: 'center',
                              rotation: 0,
                              formatter: function(value,) {
                                if(value > 5){
                                  return `${value }%`
                                 }else{
                                  return ``
                                 }
                              },
                              font: {
                                family:'SamsungOne',
                                size:'10px'
                          }
                            }
                          },
                          {
                            label : 'Negative',
                            data: chartPhasesDriversLaunch?.negative_percentage,
                            borderWidth: 1,
                            borderColor:"#ff8585",
                            backgroundColor:"#ff8585",
                            indexAxis: 'y',
                            datalabels: {
                              color: '#000',
                              align: 'center',
                              anchor: 'center',
                              rotation: 0,
                              formatter: function(value,) {
                                if(value > 5){
                                  return `${value }%`
                                 }else{
                                  return ``
                                 }
                              },
                              font: {
                                family:'SamsungOne',
                                size:'10px'
                          }
                            }
                          }
                        ],
                      
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true
                              }
                            }
                          }
                  }}/>

                      </div>
                  </div>
                </div>

                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Post Launch</div> | <div className="total__conv__count"> conversation vol:  {chartPhasesDriversPostLaunchTotal && chartPhasesDriversPostLaunchTotal["total conversations"]}
                  </div> | <div className="total__conv__count">  NPS:   {chartPhasesDriversPostLaunchTotal?.NPS}%</div></div>
                      <div className="card-body">
                    
                      <Bar  height="250vh" data={{
                      responsive: true,
                      labels:chartPhasesDriversPostLaunch?.labels,
                        datasets: [{
                            label : 'Positive',
                            data: chartPhasesDriversPostLaunch?.positive_percentage,
                            borderWidth: 1,
                            borderColor:"#bcd8a8",
                            backgroundColor: "#bcd8a8",
                            indexAxis: 'y',
                            // datalabels: {
                            //   display:false,
                            // },
                            datalabels: {
                              color: '#000',
                              align: 'center',
                              anchor: 'center',
                              rotation: 0,
                              formatter: function(value,) {
                                if(value > 5){
                                  return `${value }%`
                                 }else{
                                  return ``
                                 }
                              },
                              font: {
                                family:'SamsungOne',
                                size:'10px'
                          }
                            }
                          },
                          {
                            label : 'Negative',
                            data: chartPhasesDriversPostLaunch?.negative_percentage,
                            borderWidth: 1,
                            borderColor:"#ff8585",
                            backgroundColor:"#ff8585",
                            indexAxis: 'y',
                            datalabels: {
                              color: '#000',
                              align: 'center',
                              anchor: 'center',
                              rotation: 0,
                              formatter: function(value,) {
                                if(value > 5){
                                  return `${value }%`
                                 }else{
                                  return ``
                                 }
                              },
                              font: {
                                family:'SamsungOne',
                                size:'10px'
                          }
                            }
                          }
                        ],
                      
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              y: {
                                beginAtZero: true,
                                font: {
                                  family:'SamsungOne'
                            }
                              },
                              x: {
                                beginAtZero: true,
                                font: {
                                  family:'SamsungOne'
                            }
                              },
                              
                            }
                          }
                  }}/>

                      </div>
                  </div>
                </div>


          </div>
</section>
} 

<section className='home__section3 home__section32'>
<div className="row">
                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Pre Launch </div> | <div className="total__conv__count"> conversation vol:  {chartPhasesDriversPreLaunchTotal && chartPhasesDriversPreLaunchTotal["total conversations"]}
                  </div> | <div className="total__conv__count">  NPS:   {chartPhasesDriversPreLaunchTotal?.NPS}%</div></div>
                      <div className="card-body">
                    
                      <Bar  height="250vh" data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:chartPhasesPlatformNpsPreLaunch?.labels,
                                    datasets: [
                                      {
                                        label: 'Conversation',
                                        data:chartPhasesPlatformNpsPreLaunch?.distribution,
                                        borderColor:"#a4dcff",
                                        backgroundColor: "#a4dcff",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          formatter: function(value,) {
                                            return `${value }%`
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                       
                                      },
                                      {
                                        label: 'NPS',
                                        data:chartPhasesPlatformNpsPreLaunch?.data_nps,
                                        borderColor:"#ffc6a3",
                                        backgroundColor:"#ffc6a3",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          formatter: function(value,) {
                                            return `${value }%`
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                      }
                                    ],
                                    options: {
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                       
                                        },
                                        font: {
                                          family:'SamsungOne'
                                    }
                                      }
                                    },
                                  }}/>

                      </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Launch</div> | <div className="total__conv__count"> conversation vol:  {chartPhasesDriversLaunchTotal && chartPhasesDriversLaunchTotal["total conversations"]}
                  </div> | <div className="total__conv__count">  NPS:   {chartPhasesDriversLaunchTotal?.NPS}%</div></div>
                      <div className="card-body">
                    
                      <Bar height="250vh" data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:chartPhasesPlatformNpsLaunch?.labels,
                                    datasets: [
                                      {
                                        label: 'Conversation',
                                        data:chartPhasesPlatformNpsLaunch?.distribution,
                                        borderColor:"#a4dcff",
                                        backgroundColor: "#a4dcff",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          formatter: function(value,) {
                                            return `${value }%`
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                        
                                      },
                                      {
                                        label: 'NPS',
                                        data:chartPhasesPlatformNpsLaunch?.data_nps,
                                        borderColor:"#ffc6a3",
                                        backgroundColor:"#ffc6a3",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          formatter: function(value,) {
                                            return `${value }%`
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                      }
                                    ],
                                    options: {
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                     
                                        },
                                        font: {
                                          family:'SamsungOne'
                                    }
                                      }
                                    },
                                  }}/>

                      </div>
                  </div>
                </div>

                <div className="col-xl-4 col-md-4">
                  <div className="card">
                  <div className="card-header bgnone d-flex justify-content-between"><div className="sec__title">Post Launch</div> | <div className="total__conv__count"> conversation vol:  {chartPhasesDriversPostLaunchTotal && chartPhasesDriversPostLaunchTotal["total conversations"]}
                  </div> | <div className="total__conv__count">  NPS:   {chartPhasesDriversPostLaunchTotal?.NPS}%</div></div>
                      <div className="card-body">
                    
                      <Bar height="250vh" data={{
                                    responsive:true,
                                    type: 'bar',
                                    labels:chartPhasesPlatformNpsPostLaunch?.labels,
                                    datasets: [
                                      {
                                        label: 'Conversation',
                                        data:chartPhasesPlatformNpsPostLaunch?.distribution,
                                        borderColor:"#a4dcff",
                                        backgroundColor: "#a4dcff",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          formatter: function(value,) {
                                            return `${value }%`
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                      },
                                      {
                                        label: 'NPS',
                                        data:chartPhasesPlatformNpsPostLaunch?.data_nps,
                                        borderColor:"#ffc6a3",
                                        backgroundColor:"#ffc6a3",
                                        datalabels: {
                                          color: '#000',
                                          align: 'center',
                                          anchor: 'center',
                                          rotation: -90,
                                          formatter: function(value,) {
                                            return `${value }%`
                                          },
                                          font: {
                                            family:'SamsungOne',
                                            size:'10px'
                                      }
                                        }
                                      }
                                    ],
                                    options: {
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                          font: {
                                            family:'SamsungOne'
                                      }
                                        },
                                        title: {
                                          display: true,
                                
                                        },
                                        font: {
                                          family:'SamsungOne'
                                    }
                                      }
                                    },
                                  }}/>

                      </div>
                  </div>
                </div>


          </div>
</section>
          </div>


          <div className={`tab-page ${activeTab === 'tab-4' ? 'active' : ''}`}>
          <section className='twitter__comment__lisouter'>
<div className="row">

<div className="col-xl-12 col-md-12">
  <div className="card">
      <div className="card-header bgnone">{platformChange ? selectedplatformName : defaultPlatformValue.platform} comments</div>
      <div className="card-body">
      <section className="twittercomments">
          <div className="topic__listing">
              {topicList && topicList.map((item, index)=>(
                
                <div className="form-check" key={item.id}>
            {item.id === 6 ? <label className="form-check-label">
              <input className="form-check-input" name="topic" onChange={handleChangeTopic} type="radio" value={item.id} defaultChecked/>{item.topic_name}</label> : <label className="form-check-label">
              <input className="form-check-input" name="topic"  onChange={handleChangeTopic} type="radio" value={item.id} />{item.topic_name}</label>}
                
                </div>
              ))}
          </div>

          {loadinghome ?  <Loadding/> :
           <div className="downloadExcell"><button className="btn btn-primary" onClick={handleTopicDataDownload}>Download Excel</button></div>
        }
        

        </section>
        



        </div>
        </div>
        </div>
        </div>

</section>






<section className="home__section4__table">
<div className="table-responsive">
<div className="card">
<div className="card-body">
{/* {loadinghome ?  <Loadding/> : <DownloadData downloadTabledataHeaders={downloadTabledataHeaders} downloadTabledata={downloadTabledata}/>} */}


<div className="data__only_excelDownload">
          <div className="downloaddata__label">Download User Data</div>
        <div className="downloadExcell">
        {loadinghome ?  <Loadding/> :
         <button className="btn btn-primary" onClick={handleDownloadexcel}>Download Excel</button>
        }
          
          </div>
        </div>



</div>
</div>
</div>
</section>


          </div>



        </div>
            </section>


        </section>



{showPopup === true ? <div className='white__bg'>
<div className='hits__innercontent'>
<div className='close__icon' onClick={closeHints}>X</div>
<div className='body__content'>
  <h2>Note</h2>
<p>To do fuzzy match search as a filter criteria please use the below guidelines:</p>
<ul>
  <li>+ stands for AND</li>
  <li>- stands for NOT</li>
  <li>[no operator] implies OR</li>
</ul>
</div>
</div>
</div> : ``}



        <footer className="pc-footer">
    <div className="footer-wrapper container-fluid">
      <div className="row">
        <div className="col my-1">
          <p className="m-0">Cheil South West Asia</p>
        </div>
        
      </div>
    </div>
  </footer> 

  
        </>
    )
}

export default Home