import { Menu, Search, Bell, User, LogOut} from "react-feather"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react";
import React, { useRef } from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useAuth} from '../context/AuthContext'
import axiosInstance from '../services/AuthInterceptor'

const Header = ({visibility, isVisible}) =>{

    const navigate = useNavigate();

    const [hederUser, sethederUser] = useState(false);
    //const getToken = localStorage.getItem('userToken')
    //const getUserType = localStorage.getItem('userRole')
    const [getToken, setgetToken] = useState('')
    //const [getUserType, setgetUserType] = useState('')
  const  getUserType = JSON.parse(localStorage.getItem('userRole'))

    const{logout} = useAuth()
  
    
const showloginuser = () =>{
    sethederUser(!hederUser)
}

useEffect(()=>{
    setgetToken(localStorage.getItem('userToken'))
    //setgetUserType(JSON.parse(localStorage.getItem('userRole')))
    window.addEventListener('storage', () => {
       navigate('/')
    })
},[])

const logoutUser = () =>{
    window.localStorage.clear();
    logout();
    navigate('/login')
    window.history.pushState(null, null, window.location.href)
}


    return(
        <>
        <header className={isVisible ? "pc-header" : "pc-header active"}>
            <div className="header__outer">
                <div className="header__outer__left">
            <section className="pc-header__inner">
                <div className="header__logo">
                <img src={process.env.PUBLIC_URL + '/pulse_logo.png'} width="70px" alt="logo" title="logo"/>
                </div>
                <div className="header__menu">
                    <div className="menu_inner">
                        <ul>
                            <li>
                                <NavLink to="/">Dashboard</NavLink>
                            </li>
                            <li>
                                <NavLink to="/comparison">Comparison</NavLink>
                            </li>
                            <li>
                                <NavLink to="/engagement">engagement</NavLink>
                            </li>
                           
                           
                            {getUserType == "admin" ?
                            <>
                            <li>
                                <NavLink to="/admin/adduser">Add user</NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/delete">Delete user</NavLink>
                            </li></> : null }
                        </ul>
                        </div>        
                </div>   

                
     
                </section>
                </div>

<div className="header__outer__right">
<div className="login__logout__btn">
    <ul>
                {/* {getToken ? <LogoutButton/> : <LoginButton/>} */}

             <li className="pc-item" onClick={logoutUser}>
        <Link to="#" className="pc-link">
            <span className="pc-micon"><LogOut/></span>
            <span className="pc-mtext">Logout</span>
          </Link>
        </li>



                </ul>
                    </div>   
</div>

                </div>
        </header>

        
        
        </>
    )
}

export default Header